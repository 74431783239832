import React from 'react';

function Stopwatch({ time, isRunning, onStart, onPause, onReset, onSave, savedTimes }) {
  const formatTime = value => {
    return value.toString().padStart(2, '0');
  };

  const hours = formatTime(Math.floor(time / 3600));
  const minutes = formatTime(Math.floor((time % 3600) / 60));
  const seconds = formatTime(time % 60);

  return (
    <div className="space-y-6">
      {/* Time Display */}
      <div className="flex flex-col items-center">
        <div className="flex items-center space-x-2">
          <div className="flex flex-col items-center">
            <span className="text-6xl font-bold text-gray-900 dark:text-white">{hours}</span>
            <span className="text-sm text-gray-500 dark:text-gray-400">Horas</span>
          </div>
          <span className="text-6xl font-bold text-gray-900 dark:text-white">:</span>
          <div className="flex flex-col items-center">
            <span className="text-6xl font-bold text-gray-900 dark:text-white">{minutes}</span>
            <span className="text-sm text-gray-500 dark:text-gray-400">Minutos</span>
          </div>
          <span className="text-6xl font-bold text-gray-900 dark:text-white">:</span>
          <div className="flex flex-col items-center">
            <span className="text-6xl font-bold text-gray-900 dark:text-white">{seconds}</span>
            <span className="text-sm text-gray-500 dark:text-gray-400">Segundos</span>
          </div>
        </div>
      </div>

      {/* Controls */}
      <div className="flex justify-center space-x-4">
        {!isRunning ? (
          <button
            onClick={onStart}
            className="px-6 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-colors duration-200"
          >
            Iniciar
          </button>
        ) : (
          <button
            onClick={onPause}
            className="px-6 py-2 bg-yellow-600 text-white rounded-lg hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 transition-colors duration-200"
          >
            Pausar
          </button>
        )}
        <button
          onClick={onReset}
          className="px-6 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-colors duration-200"
        >
          Resetar
        </button>
        <button
          onClick={onSave}
          className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-200"
        >
          Salvar
        </button>
      </div>

      {/* Saved Times */}
      {savedTimes.length > 0 && (
        <div className="mt-8">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
            Tempos Salvos
          </h3>
          <div className="space-y-2">
            {savedTimes.map(savedTime => (
              <div
                key={savedTime.id}
                className="flex justify-between items-center p-4 bg-gray-50 dark:bg-gray-700 rounded-lg"
              >
                <span className="text-lg font-medium text-gray-900 dark:text-white">
                  {formatTime(Math.floor(savedTime.time / 3600))}:
                  {formatTime(Math.floor((savedTime.time % 3600) / 60))}:
                  {formatTime(savedTime.time % 60)}
                </span>
                <span className="text-sm text-gray-500 dark:text-gray-400">{savedTime.date}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Stopwatch;
