import React from 'react';
import { Link } from 'react-router-dom';
import { FaUtensils, FaRunning, FaBook } from 'react-icons/fa';

function PredefinedTimers() {
  const timers = {
    cooking: {
      'boil-eggs': { name: 'Ovos Cozidos', time: { hours: 0, minutes: 10, seconds: 0 } },
      pasta: { name: 'Massa', time: { hours: 0, minutes: 12, seconds: 0 } },
      rice: { name: 'Arroz', time: { hours: 0, minutes: 20, seconds: 0 } },
      chicken: { name: 'Frango', time: { hours: 0, minutes: 30, seconds: 0 } },
      beef: { name: 'Carne', time: { hours: 0, minutes: 45, seconds: 0 } },
      fish: { name: 'Peixe', time: { hours: 0, minutes: 15, seconds: 0 } },
      vegetables: { name: 'Legumes', time: { hours: 0, minutes: 8, seconds: 0 } },
      soup: { name: 'Sopa', time: { hours: 0, minutes: 25, seconds: 0 } },
      cake: { name: 'Bolo', time: { hours: 0, minutes: 40, seconds: 0 } },
      bread: { name: 'Pão', time: { hours: 0, minutes: 35, seconds: 0 } },
    },
    exercise: {
      'warm-up': { name: 'Aquecimento', time: { hours: 0, minutes: 5, seconds: 0 } },
      stretching: { name: 'Alongamento', time: { hours: 0, minutes: 10, seconds: 0 } },
      plank: { name: 'Prancha', time: { hours: 0, minutes: 1, seconds: 0 } },
      'push-ups': { name: 'Flexões', time: { hours: 0, minutes: 2, seconds: 0 } },
      squats: { name: 'Agachamentos', time: { hours: 0, minutes: 3, seconds: 0 } },
      'jumping-jacks': { name: 'Polichinelos', time: { hours: 0, minutes: 1, seconds: 30 } },
      burpees: { name: 'Burpees', time: { hours: 0, minutes: 1, seconds: 0 } },
      'mountain-climbers': { name: 'Escaladores', time: { hours: 0, minutes: 1, seconds: 30 } },
      lunges: { name: 'Avanços', time: { hours: 0, minutes: 2, seconds: 0 } },
      'sit-ups': { name: 'Abdominais', time: { hours: 0, minutes: 2, seconds: 0 } },
    },
    study: {
      pomodoro: { name: 'Pomodoro', time: { hours: 0, minutes: 25, seconds: 0 } },
      'short-break': { name: 'Pausa Curta', time: { hours: 0, minutes: 5, seconds: 0 } },
      'long-break': { name: 'Pausa Longa', time: { hours: 0, minutes: 15, seconds: 0 } },
      reading: { name: 'Leitura', time: { hours: 0, minutes: 30, seconds: 0 } },
      writing: { name: 'Escrita', time: { hours: 0, minutes: 45, seconds: 0 } },
      review: { name: 'Revisão', time: { hours: 0, minutes: 20, seconds: 0 } },
      practice: { name: 'Prática', time: { hours: 0, minutes: 40, seconds: 0 } },
      memorization: { name: 'Memorização', time: { hours: 0, minutes: 15, seconds: 0 } },
      'problem-solving': {
        name: 'Resolução de Problemas',
        time: { hours: 0, minutes: 50, seconds: 0 },
      },
      'group-study': { name: 'Estudo em Grupo', time: { hours: 1, minutes: 0, seconds: 0 } },
    },
  };

  const formatTime = time => {
    const { hours, minutes, seconds } = time;
    const parts = [];
    if (hours > 0) parts.push(`${hours}h`);
    if (minutes > 0) parts.push(`${minutes}m`);
    if (seconds > 0) parts.push(`${seconds}s`);
    return parts.join(' ');
  };

  return (
    <div className="space-y-8">
      {/* Cooking Timers */}
      <div>
        <div className="flex items-center space-x-2 mb-4">
          <FaUtensils className="w-5 h-5 text-red-500" />
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Cozinha</h3>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {Object.entries(timers.cooking).map(([id, timer]) => (
            <Link
              key={id}
              to={`/timer/${id}`}
              className="block p-4 bg-gray-50 dark:bg-gray-700 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors"
            >
              <div className="flex justify-between items-center">
                <span className="text-lg font-medium text-gray-900 dark:text-white">
                  {timer.name}
                </span>
                <span className="text-sm text-gray-500 dark:text-gray-400">
                  {formatTime(timer.time)}
                </span>
              </div>
            </Link>
          ))}
        </div>
      </div>

      {/* Exercise Timers */}
      <div>
        <div className="flex items-center space-x-2 mb-4">
          <FaRunning className="w-5 h-5 text-green-500" />
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Exercícios</h3>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {Object.entries(timers.exercise).map(([id, timer]) => (
            <Link
              key={id}
              to={`/timer/${id}`}
              className="block p-4 bg-gray-50 dark:bg-gray-700 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors"
            >
              <div className="flex justify-between items-center">
                <span className="text-lg font-medium text-gray-900 dark:text-white">
                  {timer.name}
                </span>
                <span className="text-sm text-gray-500 dark:text-gray-400">
                  {formatTime(timer.time)}
                </span>
              </div>
            </Link>
          ))}
        </div>
      </div>

      {/* Study Timers */}
      <div>
        <div className="flex items-center space-x-2 mb-4">
          <FaBook className="w-5 h-5 text-blue-500" />
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Estudos</h3>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {Object.entries(timers.study).map(([id, timer]) => (
            <Link
              key={id}
              to={`/timer/${id}`}
              className="block p-4 bg-gray-50 dark:bg-gray-700 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors"
            >
              <div className="flex justify-between items-center">
                <span className="text-lg font-medium text-gray-900 dark:text-white">
                  {timer.name}
                </span>
                <span className="text-sm text-gray-500 dark:text-gray-400">
                  {formatTime(timer.time)}
                </span>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PredefinedTimers;
