export const BRAZILIAN_CITIES = [
  { name: 'São Paulo', state: 'SP', coordinates: { lat: -23.5505, lon: -46.6333 } },
  { name: 'Rio de Janeiro', state: 'RJ', coordinates: { lat: -22.9068, lon: -43.1729 } },
  { name: 'Brasília', state: 'DF', coordinates: { lat: -15.7801, lon: -47.9292 } },
  { name: 'Salvador', state: 'BA', coordinates: { lat: -12.9714, lon: -38.5014 } },
  { name: 'Fortaleza', state: 'CE', coordinates: { lat: -3.7172, lon: -38.5433 } },
  { name: 'Belo Horizonte', state: 'MG', coordinates: { lat: -19.9167, lon: -43.9345 } },
  { name: 'Manaus', state: 'AM', coordinates: { lat: -3.1019, lon: -60.025 } },
  { name: 'Curitiba', state: 'PR', coordinates: { lat: -25.4297, lon: -49.2719 } },
  { name: 'Recife', state: 'PE', coordinates: { lat: -8.0476, lon: -34.877 } },
  { name: 'Porto Alegre', state: 'RS', coordinates: { lat: -30.0346, lon: -51.2177 } },
  { name: 'Belém', state: 'PA', coordinates: { lat: -1.4558, lon: -48.4902 } },
  { name: 'Goiânia', state: 'GO', coordinates: { lat: -16.6799, lon: -49.255 } },
  { name: 'Guarulhos', state: 'SP', coordinates: { lat: -23.4558, lon: -46.5366 } },
  { name: 'Campinas', state: 'SP', coordinates: { lat: -22.9099, lon: -47.0626 } },
  { name: 'São Luís', state: 'MA', coordinates: { lat: -2.5391, lon: -44.307 } },
  { name: 'São Gonçalo', state: 'RJ', coordinates: { lat: -22.8267, lon: -43.0634 } },
  { name: 'Maceió', state: 'AL', coordinates: { lat: -9.6658, lon: -35.735 } },
  { name: 'Duque de Caxias', state: 'RJ', coordinates: { lat: -22.7727, lon: -43.3125 } },
  { name: 'Natal', state: 'RN', coordinates: { lat: -5.7945, lon: -35.212 } },
  { name: 'Campo Grande', state: 'MS', coordinates: { lat: -20.4486, lon: -54.6295 } },
];
