import React, { useState, useEffect } from 'react';

function Timer({
  initialTime = { hours: 0, minutes: 0, seconds: 0 },
  isRunning,
  isPaused,
  onStart,
  onPause,
  onStop,
  onTimeChange
}) {
  const [time, setTime] = useState(initialTime);
  const [savedTimes, setSavedTimes] = useState([]);

  // Atualiza o tempo quando initialTime muda
  useEffect(() => {
    setTime(initialTime);
  }, [initialTime]);

  const handleTimeChange = (field, value) => {
    if (!isRunning) {
      const numValue = parseInt(value) || 0;
      const maxValue = field === 'hours' ? 23 : 59;
      if (numValue >= 0 && numValue <= maxValue) {
        setTime(prev => ({ ...prev, [field]: numValue }));
        // Chama a função onTimeChange do componente pai, se fornecida
        if (onTimeChange) {
          onTimeChange(field, numValue);
        }
      }
    }
  };

  const formatTime = value => {
    return value.toString().padStart(2, '0');
  };

  const handleSave = () => {
    const now = new Date();
    setSavedTimes(prev =>
      [
        {
          id: Date.now(),
          time: { ...time },
          date: now.toLocaleString(),
        },
        ...prev,
      ].slice(0, 3)
    );
  };

  return (
    <div className="space-y-6">
      {/* Time Display */}
      <div className="flex flex-col items-center">
        <div className="flex items-center space-x-2">
          <div className="flex flex-col items-center">
            <input
              type="number"
              min="0"
              max="23"
              value={time.hours}
              onChange={e => handleTimeChange('hours', e.target.value)}
              className="w-20 text-6xl font-bold text-center bg-transparent border-b-2 border-gray-300 focus:border-blue-500 focus:outline-none dark:border-gray-600 dark:focus:border-blue-400 dark:text-white"
              disabled={isRunning}
            />
            <span className="text-sm text-gray-500 dark:text-gray-400">Horas</span>
          </div>
          <span className="text-6xl font-bold text-gray-900 dark:text-white">:</span>
          <div className="flex flex-col items-center">
            <input
              type="number"
              min="0"
              max="59"
              value={time.minutes}
              onChange={e => handleTimeChange('minutes', e.target.value)}
              className="w-20 text-6xl font-bold text-center bg-transparent border-b-2 border-gray-300 focus:border-blue-500 focus:outline-none dark:border-gray-600 dark:focus:border-blue-400 dark:text-white"
              disabled={isRunning}
            />
            <span className="text-sm text-gray-500 dark:text-gray-400">Minutos</span>
          </div>
          <span className="text-6xl font-bold text-gray-900 dark:text-white">:</span>
          <div className="flex flex-col items-center">
            <input
              type="number"
              min="0"
              max="59"
              value={time.seconds}
              onChange={e => handleTimeChange('seconds', e.target.value)}
              className="w-20 text-6xl font-bold text-center bg-transparent border-b-2 border-gray-300 focus:border-blue-500 focus:outline-none dark:border-gray-600 dark:focus:border-blue-400 dark:text-white"
              disabled={isRunning}
            />
            <span className="text-sm text-gray-500 dark:text-gray-400">Segundos</span>
          </div>
        </div>
      </div>

      {/* Controls */}
      <div className="flex justify-center space-x-4">
        {!isRunning ? (
          <button
            onClick={onStart}
            className="px-6 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-colors duration-200"
          >
            Iniciar
          </button>
        ) : isPaused ? (
          <button
            onClick={onStart}
            className="px-6 py-2 bg-yellow-600 text-white rounded-lg hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 transition-colors duration-200"
          >
            Retomar
          </button>
        ) : (
          <button
            onClick={onPause}
            className="px-6 py-2 bg-yellow-600 text-white rounded-lg hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 transition-colors duration-200"
          >
            Pausar
          </button>
        )}
        <button
          onClick={onStop}
          className="px-6 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-colors duration-200"
        >
          Resetar
        </button>
        <button
          onClick={handleSave}
          className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-200"
        >
          Salvar
        </button>
      </div>

      {/* Saved Times */}
      {savedTimes.length > 0 && (
        <div className="mt-8">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
            Timers Salvos
          </h3>
          <div className="space-y-2">
            {savedTimes.map(savedTime => (
              <div
                key={savedTime.id}
                className="flex justify-between items-center p-4 bg-gray-50 dark:bg-gray-700 rounded-lg"
              >
                <span className="text-lg font-medium text-gray-900 dark:text-white">
                  {formatTime(savedTime.time.hours)}:{formatTime(savedTime.time.minutes)}:
                  {formatTime(savedTime.time.seconds)}
                </span>
                <span className="text-sm text-gray-500 dark:text-gray-400">{savedTime.date}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Timer;
