import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { FaClock, FaCalendarAlt, FaUserAlt } from 'react-icons/fa';
import { articles } from '../data/articles';

const ArticlePage = () => {
  const { articleId } = useParams();
  const [article, setArticle] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const articleData = articles[articleId];
    setArticle(articleData);
    setIsLoading(false);
  }, [articleId]);

  if (isLoading) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="animate-pulse">
          <div className="h-8 bg-gray-200 rounded w-3/4 mb-4"></div>
          <div className="h-4 bg-gray-200 rounded w-1/4 mb-8"></div>
          <div className="h-64 bg-gray-200 rounded mb-8"></div>
          <div className="space-y-4">
            <div className="h-4 bg-gray-200 rounded w-full"></div>
            <div className="h-4 bg-gray-200 rounded w-5/6"></div>
            <div className="h-4 bg-gray-200 rounded w-4/6"></div>
          </div>
        </div>
      </div>
    );
  }

  if (!article) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8 text-center">
        <h1 className="text-2xl font-bold mb-4">Artigo não encontrado</h1>
        <p className="text-gray-600 mb-8">
          O artigo que você está procurando não existe ou foi removido.
        </p>
        <Link
          to="/blog"
          className="inline-block bg-green-500 text-white px-6 py-3 rounded-lg hover:bg-green-600 transition-colors"
        >
          Voltar para o Blog
        </Link>
      </div>
    );
  }

  const renderContent = content => {
    switch (content.type) {
      case 'text':
        return <p className="mb-6 text-gray-700 leading-relaxed">{content.content}</p>;
      case 'heading':
        return <h2 className="text-2xl font-bold mb-6 mt-8 text-gray-800">{content.content}</h2>;
      case 'list':
        return (
          <ul className="list-disc pl-6 mb-6 space-y-2 text-gray-700">
            {content.content.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        );
      case 'image':
        return (
          <div className="my-8">
            <img
              src={content.content}
              alt={content.caption}
              className="w-full rounded-lg shadow-lg"
            />
            {content.caption && (
              <p className="text-center text-gray-500 mt-2 text-sm">{content.caption}</p>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <article className="prose prose-lg max-w-none">
        <h1 className="text-4xl font-bold mb-4 text-gray-800">{article.title}</h1>
        <div className="flex items-center space-x-4 text-gray-600 mb-8">
          <span className="flex items-center">
            <FaUserAlt className="mr-2" />
            {article.author}
          </span>
          <span className="flex items-center">
            <FaCalendarAlt className="mr-2" />
            {article.date}
          </span>
          <span className="flex items-center">
            <FaClock className="mr-2" />
            {article.readTime} de leitura
          </span>
        </div>
        <div className="mb-8">
          <img
            src={article.image}
            alt={article.title}
            className="w-full h-96 object-cover rounded-lg shadow-lg"
          />
        </div>
        <div className="mb-8">
          {article.content.map((content, index) => (
            <div key={index}>{renderContent(content)}</div>
          ))}
        </div>
        <div className="border-t pt-8">
          <h3 className="text-2xl font-bold mb-6 text-gray-800">Artigos Relacionados</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {article.related.map((related, index) => (
              <Link key={index} to={`/blog/${related.id}`} className="block group">
                <div className="border rounded-lg overflow-hidden hover:shadow-lg transition-shadow">
                  <img
                    src={related.image}
                    alt={related.title}
                    className="w-full h-48 object-cover"
                  />
                  <div className="p-4">
                    <h4 className="font-semibold text-lg mb-2 group-hover:text-green-500 transition-colors">
                      {related.title}
                    </h4>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </article>
    </div>
  );
};

export default ArticlePage;
