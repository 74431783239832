import React, { useState, useEffect } from 'react';
import { createBrowserRouter, RouterProvider, Link, useParams } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import TimerPage from './pages/TimerPage';
import GenerateImages from './pages/GenerateImages';
import WeatherPage from './pages/WeatherPage';
import WeatherDetail from './pages/WeatherDetail';
import BlogPage from './pages/BlogPage';
import ArticlePage from './pages/ArticlePage';
import ErrorPage from './pages/ErrorPage';

// Componente para Sobre
const AboutPage = () => {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">Sobre Nós</h1>
          <p className="text-xl text-gray-600 dark:text-gray-300">
            Conheça mais sobre o Relógio Online
          </p>
        </div>
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8">
          <div className="prose prose-lg dark:prose-invert max-w-none">
            <p>
              O Relógio Online é uma aplicação web criada para ajudar as pessoas a gerenciar seu
              tempo de forma eficiente. Nossa missão é fornecer ferramentas simples e intuitivas que
              ajudem nossos usuários a acompanhar o tempo, definir timers e alarmes para aumentar a
              produtividade.
            </p>
            <p>
              Desenvolvido por uma equipe apaixonada por tecnologia e produtividade, o Relógio
              Online oferece uma interface limpa e moderna, com suporte a modo escuro e uma
              experiência responsiva em qualquer dispositivo.
            </p>
            <h2>Recursos Principais</h2>
            <ul>
              <li>Relógio digital com horário atual</li>
              <li>Cronômetro para medir intervalos de tempo</li>
              <li>Alarmes personalizáveis</li>
              <li>Timers pré-configurados para várias atividades</li>
              <li>Modo escuro para uso noturno</li>
              <li>Design responsivo para todos os dispositivos</li>
            </ul>
            <h2>Nossa Equipe</h2>
            <p>
              Somos um grupo de desenvolvedores e designers dedicados a criar ferramentas que
              melhoram a vida das pessoas. Acreditamos que a gestão eficiente do tempo é essencial
              para o sucesso pessoal e profissional.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

// Componente para Políticas
const PrivacyPolicyPage = () => {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Política de Privacidade
          </h1>
        </div>
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8">
          <div className="prose prose-lg dark:prose-invert max-w-none">
            <p>
              Sua privacidade é importante para nós. Esta política de privacidade explica quais
              informações coletamos e como as utilizamos.
            </p>
            <h2>Informações que coletamos</h2>
            <p>
              O Relógio Online coleta apenas dados de preferências locais através de localStorage
              para melhorar sua experiência, como preferências de modo escuro e alarmes
              configurados. Esses dados são armazenados apenas no seu dispositivo.
            </p>
            <h2>Uso de cookies</h2>
            <p>
              Utilizamos cookies apenas para manter suas preferências de usuário e melhorar a
              experiência em nosso site. Nenhuma informação pessoal é coletada ou compartilhada com
              terceiros.
            </p>
            <h2>Alterações nesta política</h2>
            <p>
              Podemos atualizar nossa Política de Privacidade de tempos em tempos. Notificaremos
              você sobre quaisquer alterações publicando a nova Política de Privacidade nesta
              página.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

// Componente para Termos de Uso
const TermsOfUsePage = () => {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">Termos de Uso</h1>
        </div>
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8">
          <div className="prose prose-lg dark:prose-invert max-w-none">
            <p>
              Ao acessar e usar o Relógio Online, você concorda com os seguintes termos e condições.
            </p>
            <h2>Uso do serviço</h2>
            <p>
              O Relógio Online fornece ferramentas para gerenciamento de tempo. Você concorda em
              usar este serviço apenas para fins legais e de acordo com estes termos.
            </p>
            <h2>Precisão do relógio</h2>
            <p>
              Embora nos esforcemos para fornecer informações precisas de tempo, não garantimos
              absoluta precisão dos relógios, cronômetros ou alarmes. Para atividades que exigem
              precisão de tempo crítica, recomendamos o uso de dispositivos especializados.
            </p>
            <h2>Alterações nos termos</h2>
            <p>
              Reservamo-nos o direito de modificar estes termos a qualquer momento. Ao continuar a
              usar o site após essas alterações, você aceita os novos termos.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

// Componente para Cookies
const CookiesPage = () => {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Política de Cookies
          </h1>
        </div>
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8">
          <div className="prose prose-lg dark:prose-invert max-w-none">
            <p>
              Esta política de cookies explica como o Relógio Online utiliza cookies e tecnologias
              semelhantes.
            </p>
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              O que são cookies?
            </h3>
            <p>
              Cookies são pequenos arquivos de texto armazenados no seu navegador quando você visita
              nosso site. Eles permitem que o site lembre suas preferências e melhore sua
              experiência.
            </p>
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              Como usamos cookies
            </h3>
            <p>Usamos cookies para:</p>
            <ul>
              <li>Lembrar suas preferências de tema (claro/escuro)</li>
              <li>Armazenar informações sobre alarmes e timers configurados</li>
              <li>Lembrar que você aceitou nossa política de cookies</li>
            </ul>
            <h2>Gerenciamento de cookies</h2>
            <p>
              Você pode controlar e gerenciar cookies nas configurações do seu navegador. No
              entanto, desabilitar cookies pode afetar a funcionalidade do site.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

// Componente para FAQ
const FAQPage = () => {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Perguntas Frequentes
          </h1>
        </div>
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8">
          <div className="space-y-6">
            <div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Como definir um alarme?
              </h3>
              <p className="mt-2 text-gray-600 dark:text-gray-300">
                Na seção de Alarmes, insira as horas, minutos e segundos desejados e clique em
                &quot;Adicionar Alarme&quot;.
              </p>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Os alarmes são salvos quando fecho o site?
              </h3>
              <p className="mt-2 text-gray-600 dark:text-gray-300">
                Sim, os alarmes são salvos localmente no seu navegador e permanecerão disponíveis
                quando você retornar.
              </p>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Como usar os timers pré-configurados?
              </h3>
              <p className="mt-2 text-gray-600 dark:text-gray-300">
                Acesse a página Timer e selecione um dos timers pré-configurados nas categorias
                disponíveis. Clique em qualquer timer para carregá-lo.
              </p>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                O cronômetro continua rodando se eu mudar de página?
              </h3>
              <p className="mt-2 text-gray-600 dark:text-gray-300">
                Não, o cronômetro é reiniciado se você navegar para outra página. Recomendamos
                manter a página aberta enquanto usa o cronômetro.
              </p>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Como mudar para o modo escuro?
              </h3>
              <p className="mt-2 text-gray-600 dark:text-gray-300">
                Clique no ícone de lua/sol no canto superior direito da página para alternar entre
                os modos claro e escuro.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Componente para capturar erros
const ErrorBoundary = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-red-600 mb-4">Oops!</h1>
        <p className="text-xl text-gray-700 dark:text-gray-300 mb-6">Ocorreu um erro inesperado.</p>
        <a
          href="/"
          className="px-6 py-3 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors"
        >
          Voltar para a página inicial
        </a>
      </div>
    </div>
  );
};

// Definição das rotas
const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/timer',
        element: <TimerPage />,
      },
      {
        path: '/timer/:timerId',
        element: <TimerPage />,
      },
      {
        path: '/blog',
        element: <BlogPage />,
      },
      {
        path: '/blog/:articleId',
        element: <ArticlePage />,
      },
      {
        path: '/sobre',
        element: <AboutPage />,
      },
      {
        path: '/clima',
        element: <WeatherPage />,
      },
      {
        path: '/clima/:cityName',
        element: <WeatherDetail />,
      },
      {
        path: '/privacidade',
        element: <PrivacyPolicyPage />,
      },
      {
        path: '/termos',
        element: <TermsOfUsePage />,
      },
      {
        path: '/cookies',
        element: <CookiesPage />,
      },
      {
        path: '/faq',
        element: <FAQPage />,
      },
      {
        path: '/gerar-imagens',
        element: <GenerateImages />,
      },
      {
        path: '*',
        element: <ErrorPage />,
      },
    ],
  },
]);

// Componente principal que fornece o roteamento
function AppRouter() {
  return <RouterProvider router={router} />;
}

export default AppRouter;
