import React from 'react';

function GenerateImages() {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Em breve: Gerar Imagens
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300">
            Estamos trabalhando nessa funcionalidade e ela estará disponível em breve.
          </p>
        </div>
      </div>
    </div>
  );
}

export default GenerateImages;
