import React from 'react';

function ClockDisplay({ time }) {
  const formatTime = value => {
    return value.toString().padStart(2, '0');
  };

  const hours = formatTime(time.getHours());
  const minutes = formatTime(time.getMinutes());
  const seconds = formatTime(time.getSeconds());

  return (
    <div className="flex flex-col items-center">
      <div className="flex items-center space-x-2">
        <div className="flex flex-col items-center">
          <span className="text-6xl font-bold text-gray-900 dark:text-white">{hours}</span>
          <span className="text-sm text-gray-500 dark:text-gray-400">Horas</span>
        </div>
        <span className="text-6xl font-bold text-gray-900 dark:text-white">:</span>
        <div className="flex flex-col items-center">
          <span className="text-6xl font-bold text-gray-900 dark:text-white">{minutes}</span>
          <span className="text-sm text-gray-500 dark:text-gray-400">Minutos</span>
        </div>
        <span className="text-6xl font-bold text-gray-900 dark:text-white">:</span>
        <div className="flex flex-col items-center">
          <span className="text-6xl font-bold text-gray-900 dark:text-white">{seconds}</span>
          <span className="text-sm text-gray-500 dark:text-gray-400">Segundos</span>
        </div>
      </div>
      <div className="mt-4 text-lg text-gray-600 dark:text-gray-300">
        {time.toLocaleDateString('pt-BR', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })}
      </div>
    </div>
  );
}

export default ClockDisplay;
