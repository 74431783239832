import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BRAZILIAN_CITIES } from '../constants/brazilianCities';
import { fetchWeatherByCity } from '../constants/weatherConfig';
import { FaCloudSun, FaSearch, FaArrowRight, FaTrash, FaPlus } from 'react-icons/fa';

const WeatherPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCities, setFilteredCities] = useState(BRAZILIAN_CITIES);
  const [weatherData, setWeatherData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Buscar dados meteorológicos para todas as cidades
    const fetchWeatherData = async () => {
      const newWeatherData = { ...weatherData };

      for (const city of BRAZILIAN_CITIES) {
        try {
          if (
            !newWeatherData[city.name] ||
            Date.now() - (newWeatherData[city.name]?.timestamp || 0) > 30 * 60 * 1000
          ) {
            const data = await fetchWeatherByCity(`${city.name},${city.state},Brazil`);
            newWeatherData[city.name] = {
              data: data,
              timestamp: Date.now(),
            };
          }
        } catch (error) {
          console.error(`Erro ao buscar clima para ${city.name}:`, error);
        }
      }

      setWeatherData(newWeatherData);
      setLoading(false);
    };

    fetchWeatherData();

    // Scrollar para o topo quando carregar a página
    window.scrollTo(0, 0);
  }, []);

  const handleSearch = e => {
    const term = e.target.value;
    setSearchTerm(term);

    if (term.trim() === '') {
      setFilteredCities(BRAZILIAN_CITIES);
    } else {
      const filtered = BRAZILIAN_CITIES.filter(
        city =>
          city.name.toLowerCase().includes(term.toLowerCase()) ||
          city.state.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredCities(filtered);
    }
  };

  // Se estiver carregando, mostrar indicador
  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
        <div className="text-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-primary-600 mx-auto mb-4"></div>
          <p className="text-xl text-gray-600 dark:text-gray-300">
            Carregando previsão do tempo...
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Previsão do Tempo
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300 mb-6">
            Consulte a previsão do tempo para as maiores cidades do Brasil
          </p>

          <div className="max-w-xl mx-auto mb-12">
            <div className="flex items-center bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden border border-gray-200 dark:border-gray-700">
              <div className="pl-4">
                <FaSearch className="text-gray-400" />
              </div>
              <input
                type="text"
                placeholder="Buscar cidade ou estado..."
                value={searchTerm}
                onChange={handleSearch}
                className="w-full px-4 py-3 bg-transparent border-none focus:outline-none text-gray-700 dark:text-gray-300"
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredCities.map((city, index) => {
            const cityWeather = weatherData[city.name]?.data;

            return (
              <div
                key={index}
                className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow city-card"
              >
                <div className="p-6">
                  <div className="flex justify-between items-start">
                    <div>
                      <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-1">
                        {city.name}
                      </h2>
                      <p className="text-sm text-gray-500 dark:text-gray-400 mb-4">{city.state}</p>
                    </div>
                    <div className="flex items-center">
                      {cityWeather ? (
                        <div className="text-center">
                          <img
                            src={cityWeather.current.condition.icon}
                            alt={cityWeather.current.condition.text}
                            className="w-10 h-10"
                          />
                          <p className="text-lg font-bold text-gray-900 dark:text-white">
                            {cityWeather.current.temp_c}°C
                          </p>
                        </div>
                      ) : (
                        <div className="text-primary-600 dark:text-primary-400">
                          <FaCloudSun size={28} />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mt-4 p-4 bg-gray-100 dark:bg-gray-700 rounded-lg">
                    {cityWeather ? (
                      <>
                        <p className="text-center text-gray-700 dark:text-gray-300 mb-2">
                          {cityWeather.current.condition.text}
                        </p>
                        <p className="text-center text-gray-700 dark:text-gray-300 mb-3">
                          Sensação térmica: {cityWeather.current.feelslike_c}°C
                        </p>
                      </>
                    ) : (
                      <p className="text-center text-gray-700 dark:text-gray-300 mb-3">
                        Carregando dados do clima...
                      </p>
                    )}
                    <div className="mt-3 text-center">
                      <Link
                        to={`/clima/${encodeURIComponent(city.name)}`}
                        className="inline-flex items-center px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 transition-colors text-sm"
                      >
                        Ver previsão detalhada <FaArrowRight className="ml-2" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {filteredCities.length === 0 && (
          <div className="text-center py-12">
            <p className="text-xl text-gray-600 dark:text-gray-300">
              Nenhuma cidade encontrada para &quot;{searchTerm}&quot;.
            </p>
          </div>
        )}

        <div className="text-center mt-10 pt-6 border-t border-gray-200 dark:border-gray-700">
          <p className="text-gray-600 dark:text-gray-300 mb-4">
            Dados meteorológicos fornecidos por WeatherAPI.com
          </p>
        </div>
      </div>
    </div>
  );
};

export default WeatherPage;
