export const WEATHER_API_KEY = 'deae3101f90a4b3782113713250204';
export const WEATHER_API_BASE_URL = 'https://api.weatherapi.com/v1';

// Função para obter dados do clima por cidade
export const fetchWeatherByCity = async city => {
  try {
    const response = await fetch(
      `${WEATHER_API_BASE_URL}/forecast.json?key=${WEATHER_API_KEY}&q=${city}&days=5&lang=pt`
    );

    if (!response.ok) {
      throw new Error('Erro ao buscar dados do clima');
    }

    return await response.json();
  } catch (error) {
    console.error('Erro na API do clima:', error);
    throw error;
  }
};
