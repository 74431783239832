import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import CookieNotice from './CookieNotice';
import { FaBars, FaTimes, FaHome, FaClock, FaBlog, FaSun, FaMoon } from 'react-icons/fa';

function Layout() {
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location]);

  useEffect(() => {
    // Verifica se há preferência salva para o modo escuro
    const savedDarkMode = localStorage.getItem('darkMode') === 'true';
    setIsDarkMode(savedDarkMode);

    // Aplica o modo escuro se necessário
    if (savedDarkMode) {
      document.documentElement.classList.add('dark');
    }
  }, []);

  const toggleDarkMode = () => {
    const newMode = !isDarkMode;
    setIsDarkMode(newMode);
    localStorage.setItem('darkMode', newMode.toString());
    document.documentElement.classList.toggle('dark');
  };

  const isActive = path => {
    return location.pathname === path;
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div
      className={`min-h-screen flex flex-col ${
        isDarkMode ? 'dark bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'
      }`}
    >
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-md`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <Link to="/" className="text-xl font-bold text-green-600">
                  Relógio Online
                </Link>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                <Link
                  to="/"
                  className={`${
                    isActive('/')
                      ? 'border-green-500 text-gray-900 dark:text-white'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white'
                  } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
                >
                  <FaHome className="mr-2" />
                  Início
                </Link>
                <Link
                  to="/timer"
                  className={`${
                    isActive('/timer')
                      ? 'border-green-500 text-gray-900 dark:text-white'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white'
                  } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
                >
                  <FaClock className="mr-2" />
                  Timer
                </Link>
                <Link
                  to="/clima"
                  className={`${
                    isActive('/clima')
                      ? 'border-green-500 text-gray-900 dark:text-white'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white'
                  } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
                >
                  <FaSun className="mr-2" />
                  Clima
                </Link>
                <Link
                  to="/blog"
                  className={`${
                    isActive('/blog')
                      ? 'border-green-500 text-gray-900 dark:text-white'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white'
                  } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
                >
                  <FaBlog className="mr-2" />
                  Blog
                </Link>
              </div>
            </div>
            <div className="flex items-center">
              <button
                onClick={toggleDarkMode}
                className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                {isDarkMode ? <FaSun className="w-5 h-5" /> : <FaMoon className="w-5 h-5" />}
              </button>
              <button
                onClick={toggleMobileMenu}
                className="sm:hidden ml-4 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                {isMobileMenuOpen ? (
                  <FaTimes className="w-5 h-5" />
                ) : (
                  <FaBars className="w-5 h-5" />
                )}
              </button>
            </div>
          </div>

          {isMobileMenuOpen && (
            <div className="sm:hidden">
              <div className="pt-2 pb-3 space-y-1">
                <Link
                  to="/"
                  className={`${
                    isActive('/')
                      ? 'bg-green-50 border-green-500 text-green-700 dark:bg-gray-700 dark:text-white'
                      : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white'
                  } block pl-3 pr-4 py-2 border-l-4 text-base font-medium`}
                >
                  Início
                </Link>
                <Link
                  to="/timer"
                  className={`${
                    isActive('/timer')
                      ? 'bg-green-50 border-green-500 text-green-700 dark:bg-gray-700 dark:text-white'
                      : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white'
                  } block pl-3 pr-4 py-2 border-l-4 text-base font-medium`}
                >
                  Timer
                </Link>
                <Link
                  to="/clima"
                  className={`${
                    isActive('/clima')
                      ? 'bg-green-50 border-green-500 text-green-700 dark:bg-gray-700 dark:text-white'
                      : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white'
                  } block pl-3 pr-4 py-2 border-l-4 text-base font-medium`}
                >
                  Clima
                </Link>
                <Link
                  to="/blog"
                  className={`${
                    isActive('/blog')
                      ? 'bg-green-50 border-green-500 text-green-700 dark:bg-gray-700 dark:text-white'
                      : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white'
                  } block pl-3 pr-4 py-2 border-l-4 text-base font-medium`}
                >
                  Blog
                </Link>
              </div>
            </div>
          )}
        </div>
      </header>

      <main className="flex-grow">
        <Outlet />
      </main>

      <footer className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-md mt-auto`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div>
              <h3 className="text-lg font-semibold mb-4">Links Rápidos</h3>
              <ul className="space-y-2">
                <li>
                  <Link to="/" className="text-gray-400 hover:text-green-500 transition-colors">
                    Início
                  </Link>
                </li>
                <li>
                  <Link
                    to="/timer"
                    className="text-gray-400 hover:text-green-500 transition-colors"
                  >
                    Timer
                  </Link>
                </li>
                <li>
                  <Link
                    to="/clima"
                    className="text-gray-400 hover:text-green-500 transition-colors"
                  >
                    Clima
                  </Link>
                </li>
                <li>
                  <Link to="/blog" className="text-gray-400 hover:text-green-500 transition-colors">
                    Blog
                  </Link>
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-4">Legal</h3>
              <ul className="space-y-2">
                <li>
                  <Link
                    to="/privacidade"
                    className="text-gray-400 hover:text-green-500 transition-colors"
                  >
                    Política de Privacidade
                  </Link>
                </li>
                <li>
                  <Link
                    to="/termos"
                    className="text-gray-400 hover:text-green-500 transition-colors"
                  >
                    Termos de Uso
                  </Link>
                </li>
                <li>
                  <Link
                    to="/cookies"
                    className="text-gray-400 hover:text-green-500 transition-colors"
                  >
                    Cookies
                  </Link>
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-4">Contato</h3>
              <ul className="space-y-2">
                <li>
                  <Link to="/faq" className="text-gray-400 hover:text-green-500 transition-colors">
                    FAQ
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Layout;
