import React, { useState, useEffect } from 'react';
import ClockDisplay from '../components/ClockDisplay';
import Stopwatch from '../components/Stopwatch';
import Alarm from '../components/Alarm';

function Home() {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [stopwatchTime, setStopwatchTime] = useState(0);
  const [stopwatchRunning, setStopwatchRunning] = useState(false);
  const [savedTimes, setSavedTimes] = useState([]);
  const [alarms, setAlarms] = useState([]);

  // Update current time every second
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  // Update stopwatch time when running
  useEffect(() => {
    let interval;
    if (stopwatchRunning) {
      interval = setInterval(() => {
        setStopwatchTime(prevTime => prevTime + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [stopwatchRunning]);

  // Check alarms
  useEffect(() => {
    const checkAlarms = () => {
      const now = new Date();
      const currentHours = now.getHours();
      const currentMinutes = now.getMinutes();
      const currentSeconds = now.getSeconds();

      alarms.forEach(alarm => {
        if (
          alarm.active &&
          alarm.hours === currentHours &&
          alarm.minutes === currentMinutes &&
          alarm.seconds === currentSeconds
        ) {
          // Play alarm sound
          const audio = new Audio('/alarm.mp3');
          audio.play();
        }
      });
    };

    const interval = setInterval(checkAlarms, 1000);
    return () => clearInterval(interval);
  }, [alarms]);

  const handleStartStopwatch = () => {
    setStopwatchRunning(true);
  };

  const handlePauseStopwatch = () => {
    setStopwatchRunning(false);
  };

  const handleResetStopwatch = () => {
    setStopwatchTime(0);
    setStopwatchRunning(false);
  };

  const handleSaveTime = () => {
    const now = new Date();
    setSavedTimes(prev =>
      [
        {
          id: Date.now(),
          time: stopwatchTime,
          date: now.toLocaleString(),
        },
        ...prev,
      ].slice(0, 3)
    );
  };

  const handleAddAlarm = (hours, minutes, seconds) => {
    setAlarms(prev => [
      ...prev,
      {
        id: Date.now(),
        hours,
        minutes,
        seconds,
        active: true,
      },
    ]);
  };

  const handleToggleAlarm = id => {
    setAlarms(prev =>
      prev.map(alarm => (alarm.id === id ? { ...alarm, active: !alarm.active } : alarm))
    );
  };

  const handleDeleteAlarm = id => {
    setAlarms(prev => prev.filter(alarm => alarm.id !== id));
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">Relógio Online</h1>
          <p className="text-xl text-gray-600 dark:text-gray-300">
            Gerencie seu tempo com precisão e facilidade
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Clock Section */}
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">Relógio</h2>
            <ClockDisplay time={currentTime} />
          </div>

          {/* Stopwatch Section */}
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
              Cronômetro
            </h2>
            <Stopwatch
              time={stopwatchTime}
              isRunning={stopwatchRunning}
              onStart={handleStartStopwatch}
              onPause={handlePauseStopwatch}
              onReset={handleResetStopwatch}
              onSave={handleSaveTime}
              savedTimes={savedTimes}
            />
          </div>

          {/* Alarm Section */}
          <div className="md:col-span-2 bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">Alarmes</h2>
            <Alarm
              alarms={alarms}
              onAdd={handleAddAlarm}
              onToggle={handleToggleAlarm}
              onDelete={handleDeleteAlarm}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
