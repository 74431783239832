import React from 'react';
import { Link } from 'react-router-dom';

const ErrorPage = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
      <div className="text-center p-8 max-w-md">
        <h1 className="text-6xl font-bold text-red-600 mb-4">404</h1>
        <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
          Página não encontrada
        </h2>
        <p className="text-lg text-gray-600 dark:text-gray-300 mb-8">
          A página que você está procurando não existe ou foi removida.
        </p>
        <Link
          to="/"
          className="px-6 py-3 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors inline-block"
        >
          Voltar para a página inicial
        </Link>
      </div>
    </div>
  );
};

export default ErrorPage;
