export const articles = {
  pomodoro: {
    title: 'Técnica Pomodoro: Como Gerenciar o Tempo e Aumentar a Produtividade',
    excerpt:
      'Descubra como a técnica Pomodoro pode revolucionar sua produtividade e ajudar a gerenciar melhor seu tempo.',
    date: '2025-03-15',
    author: 'Carlos Oliveira',
    readTime: '6 min',
    categories: ['produtividade', 'gerenciamento de tempo'],
    image:
      'https://images.unsplash.com/photo-1514474959185-1472d4c4e0d4?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
    content: [
      {
        type: 'text',
        content:
          'Em um mundo repleto de distrações — notificações de celular, redes sociais e demandas simultâneas —, manter o foco tornou-se um desafio constante. Se você já se viu procrastinando ou lutando para concluir tarefas, a Técnica Pomodoro pode ser a solução que faltava no seu dia a dia. Criada nos anos 1980 pelo italiano Francesco Cirillo, esse método simples promete revolucionar a forma como organizamos nosso trabalho. Mas como ele funciona na prática? E por que continua relevante décadas depois? Vamos explorar!',
      },
      {
        type: 'heading',
        content: 'O Que É a Técnica Pomodoro?',
      },
      {
        type: 'text',
        content:
          'A Técnica Pomodoro (do italiano pomodoro, que significa "tomate") recebeu esse nome por causa do timer de cozinha em formato de tomate usado por Cirillo durante seus estudos universitários. A ideia central é dividir o tempo em blocos curtos e focados, alternados com intervalos de descanso. O ciclo básico segue esta estrutura:',
      },
      {
        type: 'list',
        content: [
          '25 minutos de trabalho intenso (um "pomodoro")',
          '5 minutos de pausa',
          'Após quatro pomodoros, uma pausa mais longa (15 a 30 minutos)',
        ],
      },
      {
        type: 'text',
        content:
          'Essa divisão ajuda a mente a manter a concentração sem sobrecarregar-se, aproveitando a capacidade natural de foco humano, que tende a diminuir após certo período.',
      },
      {
        type: 'heading',
        content: 'Por Que Funciona?',
      },
      {
        type: 'text',
        content:
          'A eficácia da técnica está na combinação de dois elementos: disciplina e descanso. Ao limitar o tempo de trabalho, criamos um senso de urgência que reduz a tentação de adiar tarefas. Além disso, as pausas frequentes evitam a fadiga mental, permitindo que o cérebro se recupere e retome atividades com mais energia.',
      },
      {
        type: 'text',
        content:
          'Estudos na área de produtividade indicam que intervalos regulares melhoram a retenção de informações e a qualidade do trabalho executado. Ou seja, não se trata apenas de trabalhar mais rápido, mas de fazê-lo com mais inteligência.',
      },
      {
        type: 'heading',
        content: 'Como Implementar na Prática',
      },
      {
        type: 'list',
        content: [
          'Escolha uma Tarefa Prioridade: Defina o que precisa ser feito e elimine distrações (desligue notificações, avise colegas que estará ocupado).',
          'Ajuste o Timer: Use um cronômetro físico, aplicativo (como Focus Booster ou Pomotodo) ou até mesmo o celular.',
          'Trabalhe Sem Interrupções: Durante os 25 minutos, dedique-se exclusivamente à tarefa. Se surgir uma ideia ou tarefa nova, anote-a e retome após o intervalo.',
          'Respeite os Intervalos: Levante-se, alongue-se ou faça um lanche. Evite telas durante as pausas para maximizar o descanso.',
          'Adapte-se: Se 25 minutos forem curtos demais, experimente blocos de 30 ou 45 minutos. O importante é manter a consistência.',
        ],
      },
      {
        type: 'heading',
        content: 'Benefícios Além da Produtividade',
      },
      {
        type: 'list',
        content: [
          'Redução do Estresse: Saber que há um momento para descansar diminui a ansiedade de "precisar fazer tudo agora".',
          'Maior Consciência do Tempo: Ao medir quanto cada atividade exige, você planeja melhor suas jornadas.',
          'Combate à Procrastinação: A divisão em etapas curtas torna projetos complexos mais gerenciáveis.',
        ],
      },
      {
        type: 'heading',
        content: 'Dicas Para Não Desistir',
      },
      {
        type: 'list',
        content: [
          'Comece com metas realistas: Uma ou duas sessões por dia já são um avanço.',
          'Use um quadro visual para marcar pomodoros concluídos — a sensação de progresso motiva.',
          'Se interromper um pomodoro, recomece. A disciplina melhora com a prática.',
        ],
      },
      {
        type: 'heading',
        content: 'Conclusão',
      },
      {
        type: 'text',
        content:
          'A Técnica Pomodoro não é uma fórmula mágica, mas uma ferramenta flexível que se adapta a diferentes rotinas. Seja para estudar, trabalhar em home office ou organizar tarefas domésticas, ela oferece um caminho para equilibrar produtividade e bem-estar. Que tal testar hoje mesmo? Lembre-se: o objetivo não é preencher cada minuto com atividade, mas encontrar um ritmo sustentável que respeite seus limites. Afinal, como dizia Cirillo, "o tempo é um recurso não renovável — use-o com sabedoria".',
      },
    ],
    related: [
      {
        id: 'time-blocking',
        title: 'Time Blocking: Organize seu Tempo',
        image:
          'https://images.unsplash.com/photo-1506784983877-45594efa4cbe?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2068&q=80',
      },
      {
        id: 'sono-produtividade',
        title: 'O Impacto do Sono na Produtividade',
        image:
          'https://images.unsplash.com/photo-1541781774459-bb2af2f05b55?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2060&q=80',
      },
    ],
  },
  'time-blocking': {
    title: 'Compartimentalização Temporal: A Ciência Avançada do Time Blocking',
    excerpt:
      'Descubra como dividir seu tempo em blocos estratégicos pode elevar sua eficiência, reduzir o estresse e reconectar você ao que realmente importa.',
    date: '2025-03-10',
    author: 'Dr. Ricardo Oliveira',
    readTime: '15 min',
    categories: ['produtividade', 'gerenciamento de tempo', 'ciência'],
    image:
      'https://images.unsplash.com/photo-1506784983877-45594efa4cbe?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2068&q=80',
    content: [
      {
        type: 'text',
        content:
          'Você já se sentiu como um malabarista tentando equilibrar dezenas de tarefas ao mesmo tempo? Enquanto métodos tradicionais de produtividade focam em fazer mais rápido, a compartimentalização temporal propõe uma revolução: fazer com propósito, no momento certo. Mais do que um simples agendamento, essa abordagem científica do time blocking usa princípios da psicologia cognitiva e da neurociência para transformar caos em clareza. Neste artigo, descubra como dividir seu tempo em blocos estratégicos pode elevar sua eficiência, reduzir o estresse e reconectar você ao que realmente importa.',
      },
      {
        type: 'heading',
        content: 'O Que É Compartimentalização Temporal?',
      },
      {
        type: 'text',
        content:
          'A compartimentalização temporal é a prática de dividir o dia em blocos de tempo dedicados a atividades específicas, seguindo três princípios:',
      },
      {
        type: 'list',
        content: [
          'Foco Monotarefa: Cada bloco é imune a interrupções.',
          'Alinhamento com Ritmos Biológicos: Atividades são alocadas conforme sua energia natural (ex.: tarefas criativas de manhã, administrativas à tarde).',
          'Proteção Contra a "Poluição Mental": Evitar a sobreposição de contextos (ex.: checar e-mails durante reuniões).',
        ],
      },
      {
        type: 'text',
        content:
          'Inspirada no conceito de time blocking popularizado por Cal Newport (autor de Deep Work), a versão científica incorpora descobertas como:',
      },
      {
        type: 'list',
        content: [
          'Lei de Parkinson: "Tarefas se expandem para preencher o tempo disponível". Blocos fixos combatem isso.',
          'Custos de Troca de Contexto: Estudos da Universidade da Califórnia mostram que alternar tarefas pode consumir até 40% da produtividade.',
        ],
      },
      {
        type: 'heading',
        content: 'Por Que Funciona Melhor do Que Listas de Tarefas?',
      },
      {
        type: 'text',
        content:
          'Enquanto listas respondem "O que fazer?", a compartimentalização temporal responde "Quando e como fazer?". Veja a diferença:',
      },
      {
        type: 'list',
        content: [
          'Lista de Tarefas: "Escrever relatório" → Compartimentalização Temporal: "Bloco 9h-11h: Escrita profunda (sem notificações)"',
          'Lista de Tarefas: "Reunião com equipe" → Compartimentalização Temporal: "Bloco 14h-15h: Reunião focada (com pauta pré-definida)"',
        ],
      },
      {
        type: 'text',
        content: 'Vantagens Comprovadas:',
      },
      {
        type: 'list',
        content: [
          'Redução da Fadiga Decisória: Menos escolhas = mais energia mental.',
          'Aumento da Profundidade do Trabalho: Blocos de 90-120 minutos melhoram a qualidade do output, segundo pesquisa da MIT Sloan.',
          'Previsibilidade Psicológica: Saber que há um momento para cada atividade reduz a ansiedade do "e agora?".',
        ],
      },
      {
        type: 'heading',
        content: 'Os 4 Pilares Científicos do Método',
      },
      {
        type: 'heading',
        content: 'Pilar 1: Ritmo Ultradiano',
      },
      {
        type: 'text',
        content:
          'O corpo humano opera em ciclos de 90-120 minutos de alta energia, seguidos por 20-30 minutos de baixa (estudos do Instituto Nacional de Saúde dos EUA). Use esses picos para:',
      },
      {
        type: 'list',
        content: [
          'Blocos de Trabalho Profundo: Atividades que exigem concentração máxima.',
          'Blocos de Recuperação: Descanso ativo (caminhada, meditação).',
        ],
      },
      {
        type: 'heading',
        content: 'Pilar 2: Priorização Baseada em Energia',
      },
      {
        type: 'text',
        content: 'Classifique tarefas por:',
      },
      {
        type: 'list',
        content: [
          'Energia Necessária: Alto (ex.: planejamento estratégico), Médio (ex.: responder e-mails), Baixo (ex.: organização de arquivos).',
          'Cronotipo: Se você é matutino, vespertino ou noturno, ajuste os blocos ao seu pico natural.',
        ],
      },
      {
        type: 'heading',
        content: 'Pilar 3: Buffer Antifrágil',
      },
      {
        type: 'text',
        content:
          'Reserve 10-15% do dia para imprevistos (ex.: reunião estendida, problema urgente). Isso evita o "efeito dominó" de atrasos.',
      },
      {
        type: 'heading',
        content: 'Pilar 4: Revisão de Blocos',
      },
      {
        type: 'text',
        content: 'Ao fim do dia, avalie:',
      },
      {
        type: 'list',
        content: [
          'Quais blocos foram realistas?',
          'Onde ocorreram interrupções?',
          'Como melhorar o alinhamento energia-tarefa?',
        ],
      },
      {
        type: 'heading',
        content: 'Passo a Passo para Implementar na Prática',
      },
      {
        type: 'text',
        content: 'Mapeie Seu "DNA Produtivo":',
      },
      {
        type: 'list',
        content: [
          'Por 3 dias, anote:',
          'Horários de maior foco e cansaço.',
          'Tarefas que consomem mais tempo do que o esperado.',
        ],
      },
      {
        type: 'text',
        content: 'Categorize as Atividades:',
      },
      {
        type: 'list',
        content: [
          'Tipo A: Críticas e complexas (ex.: relatórios, projetos criativos).',
          'Tipo B: Operacionais (ex.: e-mails, reuniões curtas).',
          'Tipo C: Automáticas (ex.: organização, tarefas domésticas).',
        ],
      },
      {
        type: 'text',
        content: 'Desenhe Seu Primeiro Protótipo:',
      },
      {
        type: 'list',
        content: [
          'Use uma tabela dividida em blocos de 30 ou 60 minutos.',
          'Exemplo para um dia de home office:',
          '7h-8h: Exercício + Café da Manhã (Tipo C)',
          '8h30-10h30: Desenvolvimento de Projeto (Tipo A)',
          '10h30-11h: Intervalo (café, alongamento)',
          '11h-12h: Reuniões Rápidas (Tipo B)',
        ],
      },
      {
        type: 'text',
        content: 'Use Tecnologia a Seu Favor:',
      },
      {
        type: 'list',
        content: [
          'Google Calendar ou Notion: Para blocos coloridos e lembretes.',
          'RescueTime: Monitora quanto tempo você gasta em cada atividade.',
          'Focus@Will: Músicas para melhorar a concentração durante blocos A.',
        ],
      },
      {
        type: 'heading',
        content: 'Caso Real: De Colapsos a Controle',
      },
      {
        type: 'text',
        content:
          'Pedro, 35 anos, gerente de marketing, vivia apagando incêndios até adotar a compartimentalização:',
      },
      {
        type: 'list',
        content: [
          'Desafio:',
          'Dias fragmentados por demandas urgentes.',
          '4 horas diárias gastas em redes sociais "por distração".',
          'Solução:',
          'Blocos fixos para demanda crítica (9h-12h: estratégia de conteúdo).',
          'Blocos de "emergência controlada" (15h-16h: resolver imprevistos).',
          'Uso do app Freedom para bloquear redes sociais fora dos intervalos.',
          'Resultado em 2 meses:',
          'Redução de 60% no tempo ocioso.',
          'Entrega de projetos 30% mais rápida.',
        ],
      },
      {
        type: 'heading',
        content: 'Erros que Podem Arruinar Seu Sistema',
      },
      {
        type: 'list',
        content: [
          'Blocos Muito Longos: Acima de 2 horas sem pausa levam à queda de performance. Corrija: Insira intervalos de 10 minutos a cada 90 minutos.',
          'Ignorar o Ócio Criativo: Corrija: Inclua blocos de "tempo livre" para insights espontâneos.',
          'Superestimar a Capacidade Diária: Corrija: Comece com 3-4 blocos prioritários e expanda gradualmente.',
        ],
      },
      {
        type: 'heading',
        content: 'Adaptações para Diferentes Estilos de Vida',
      },
      {
        type: 'list',
        content: [
          'Para Mães/Pais: Blocos de 15-30 minutos entre cuidados com filhos (ex.: planejar refeições durante sonecas).',
          'Para Estudantes: Blocos de estudo intercalados com revisões ativas (ex.: 50 minutos estudo + 10 minutos de flashcards).',
          'Para Empreendedores: "Dias Temáticos" (ex.: segunda-feira para operações, terça para inovação).',
        ],
      },
      {
        type: 'heading',
        content: 'Conclusão',
      },
      {
        type: 'text',
        content:
          'A compartimentalização temporal não é sobre preencher cada minuto, mas sobre respeitar o ritmo humano em um mundo que valoriza a hiperconexão. Ao transformar seu tempo em blocos estratégicos, você deixa de ser refém das demandas e passa a arquitetar dias que refletem suas prioridades reais. Lembre-se: produtividade de verdade não é fazer mais, é fazer o que importa — com presença e propósito.',
      },
      {
        type: 'heading',
        content: 'Desafio dos 7 Dias',
      },
      {
        type: 'text',
        content:
          'Experimente a compartimentalização por uma semana. No primeiro dia, planeje apenas 3 blocos essenciais. A cada dia, ajuste com base no que aprendeu. Ao final, avalie: como sua relação com o tempo mudou?',
      },
      {
        type: 'text',
        content:
          'Compartilhe sua jornada nos comentários: qual bloco de tempo vai criar primeiro? 🕒',
      },
    ],
    related: [
      {
        id: 'pomodoro',
        title: 'Técnica Pomodoro: Aumente sua Produtividade',
        image:
          'https://images.unsplash.com/photo-1434030216411-0b793f4b4173?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
      },
      {
        id: 'rotinas',
        title: 'A Importância de Estabelecer Rotinas',
        image:
          'https://images.unsplash.com/photo-1516321318423-f06f85e504b3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
      },
    ],
  },
  'sono-produtividade': {
    title: 'O Impacto do Sono na Produtividade',
    excerpt:
      'Descubra como a qualidade do sono afeta diretamente sua produtividade e aprenda estratégias para melhorar seu descanso.',
    date: '2025-02-25',
    author: 'Dr. Ana Silva',
    readTime: '8 min',
    categories: ['produtividade', 'saúde', 'bem-estar'],
    image:
      'https://images.unsplash.com/photo-1541781774459-bb2af2f05b55?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2060&q=80',
    content: [
      {
        type: 'text',
        content:
          'O sono é um dos pilares fundamentais da produtividade. Enquanto dormimos, nosso cérebro realiza processos essenciais para a consolidação da memória, processamento de informações e recuperação física. A privação de sono pode ter impactos significativos na nossa capacidade de concentração, tomada de decisões e desempenho geral.',
      },
      {
        type: 'heading',
        content: 'A Ciência do Sono',
      },
      {
        type: 'text',
        content:
          'O sono é dividido em ciclos de aproximadamente 90 minutos, cada um contendo diferentes estágios: sono leve, sono profundo e sono REM (Rapid Eye Movement). Cada estágio desempenha um papel crucial na nossa saúde e produtividade:',
      },
      {
        type: 'list',
        content: [
          'Sono leve: Transição entre vigília e sono profundo',
          'Sono profundo: Recuperação física e consolidação da memória',
          'Sono REM: Processamento emocional e criatividade',
        ],
      },
      {
        type: 'heading',
        content: 'Impacto na Produtividade',
      },
      {
        type: 'text',
        content:
          'Estudos mostram que a privação de sono pode reduzir a produtividade em até 40%. Alguns dos principais impactos incluem:',
      },
      {
        type: 'list',
        content: [
          'Redução da capacidade de concentração',
          'Prejuízo na tomada de decisões',
          'Aumento do tempo de reação',
          'Maior propensão a erros',
          'Redução da criatividade',
        ],
      },
      {
        type: 'heading',
        content: 'Estratégias para Melhorar o Sono',
      },
      {
        type: 'text',
        content:
          'Para otimizar sua produtividade através do sono, considere implementar estas estratégias:',
      },
      {
        type: 'list',
        content: [
          'Mantenha um horário regular de sono',
          'Crie um ambiente propício para o descanso',
          'Evite cafeína e álcool antes de dormir',
          'Pratique exercícios regularmente',
          'Estabeleça uma rotina de relaxamento antes de dormir',
        ],
      },
      {
        type: 'heading',
        content: 'O Poder dos Cochilos',
      },
      {
        type: 'text',
        content:
          'Cochilos estratégicos podem ser uma ferramenta poderosa para aumentar a produtividade. Um cochilo de 20-30 minutos pode:',
      },
      {
        type: 'list',
        content: [
          'Melhorar o estado de alerta',
          'Aumentar a concentração',
          'Reduzir o estresse',
          'Melhorar o humor',
          'Aumentar a criatividade',
        ],
      },
      {
        type: 'heading',
        content: 'Conclusão',
      },
      {
        type: 'text',
        content:
          'Investir em um sono de qualidade não é apenas uma questão de bem-estar, mas uma estratégia fundamental para maximizar sua produtividade. Ao priorizar o descanso adequado, você estará investindo em sua capacidade de realizar mais e melhor em todas as áreas da vida.',
      },
    ],
    related: [
      {
        id: 'pomodoro',
        title: 'Técnica Pomodoro: Aumente sua Produtividade',
        image:
          'https://images.unsplash.com/photo-1434030216411-0b793f4b4173?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
      },
      {
        id: 'time-blocking',
        title: 'Time Blocking: Organize seu Tempo',
        image:
          'https://images.unsplash.com/photo-1506784983877-45594efa4cbe?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2068&q=80',
      },
    ],
  },
  'arquitetura-habitos': {
    title: 'Arquitetura de Hábitos: Como Rotinas Bem Projetadas Transformam Sua Realidade',
    excerpt:
      'Descubra como projetar hábitos intencionais que se tornam alicerces para uma vida mais produtiva e alinhada com seus objetivos.',
    date: '2024-03-25',
    author: 'Mariana Costa',
    readTime: '10 min',
    categories: ['produtividade', 'hábitos', 'desenvolvimento pessoal'],
    image:
      'https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
    content: [
      {
        type: 'text',
        content:
          'Imagine sua vida como um edifício: cada hábito é um tijolo, e a rotina é o projeto que define se a construção será sólida ou vulnerável. Assim como um arquiteto planeja cada detalhe para garantir funcionalidade e beleza, projetar hábitos intencionais é a chave para construir uma realidade alinhada aos seus objetivos. Mas como transformar ações automáticas em alicerces de mudança? Neste artigo, exploramos a ciência e a arte por trás da arquitetura de hábitos — um método que vai além das dicas superficiais, mergulhando no design estratégico do seu cotidiano.',
      },
      {
        type: 'heading',
        content: 'O Que É a "Arquitetura de Hábitos"?',
      },
      {
        type: 'text',
        content:
          'O termo, inspirado no livro Hábitos Atômicos de James Clear, ganha aqui uma abordagem sistêmica: trata-se de projetar ambientes, gatilhos e recompensas que tornem hábitos desejáveis inevitáveis. Assim como um arquiteto considera iluminação, fluxo de pessoas e materiais, você deve analisar:',
      },
      {
        type: 'list',
        content: [
          'Contexto: Onde e quando o hábito ocorre?',
          'Microações: Quais pequenos passos levam ao comportamento desejado?',
          'Recompensas intrínsecas: Como o hábito se conecta a sua identidade ou valores?',
        ],
      },
      {
        type: 'text',
        content:
          'Exemplo prático: Em vez de "vou fazer exercícios 3x por semana", um design eficaz seria:',
      },
      {
        type: 'list',
        content: [
          'Contexto: "Toda segunda, quarta e sexta, às 7h, calço meus tênis e caminho até a academia (a 5 minutos de casa)".',
          'Microação: "Coloco a roupa de treino na cama na noite anterior".',
          'Recompensa: "Após o treino, tomo um café especial que só consumo nesses dias".',
        ],
      },
      {
        type: 'heading',
        content: 'Os 4 Pilares da Arquitetura de Hábitos',
      },
      {
        type: 'text',
        content:
          'Baseado em pesquisas de psicologia comportamental e neurociência, um hábito bem projetado depende de:',
      },
      {
        type: 'heading',
        content: 'Pilar 1: Clareza do Propósito',
      },
      {
        type: 'text',
        content: 'Pergunta-chave: "Qual identidade quero reforçar com esse hábito?"',
      },
      {
        type: 'list',
        content: [
          'Exemplo: "Sou alguém que prioriza saúde mental" → Meditação diária.',
          'Evite metas vagas como "ser mais produtivo". Substitua por: "Vou dedicar 25 minutos às tarefas críticas antes de checar e-mails".',
        ],
      },
      {
        type: 'heading',
        content: 'Pilar 2: Ambiente Propositivo',
      },
      {
        type: 'text',
        content: 'Ajuste seu espaço para reduzir a fricção:',
      },
      {
        type: 'list',
        content: [
          'Estúdios de design usam a "regra dos 20 segundos": se um hábito exige mais de 20 segundos de esforço extra para começar, é menos provável que aconteça.',
          'Faça você: Deixe o violão no sofá se quer praticar; troque o pote de biscoitos por frutas na mesa.',
        ],
      },
      {
        type: 'heading',
        content: 'Pilar 3: Ritualização de Sinais',
      },
      {
        type: 'text',
        content: 'Crie gatilhos sensoriais:',
      },
      {
        type: 'list',
        content: [
          'Uma xícara de chá específica para horas de estudo.',
          'Uma playlist só para trabalho focado (o cérebro associará a música à produtividade).',
        ],
      },
      {
        type: 'heading',
        content: 'Pilar 4: Recompensas que Alimentam a Identidade',
      },
      {
        type: 'text',
        content: 'Recompensas imediatas > Recompensas distantes:',
      },
      {
        type: 'list',
        content: [
          'Após finalizar um relatório, assista a um episódio de sua série favorita.',
          'Celebre marcos simbólicos: "7 dias de hábito concluídos → uma massagem no fim de semana".',
        ],
      },
      {
        type: 'heading',
        content: 'Caso Real: Como Redesenhar um Hábito Problemático',
      },
      {
        type: 'text',
        content: 'Ana, 32 anos, queria substituir o hábito de checar redes sociais ao acordar.',
      },
      {
        type: 'list',
        content: [
          'Diagnóstico:',
          'Gatilho: Desligar o despertador → celular ao lado da cama.',
          'Recompensa: Dopamina rápida com atualizações.',
          'Redesenho:',
          'Novo contexto: Celular carregado fora do quarto.',
          'Novo gatilho: Ao desligar o despertador, beber um copo de água.',
          'Recompensa: 5 minutos de alongamento (que geram energia imediata).',
          'Resultado: Em 3 semanas, o tempo nas redes diminuiu 70%, e Ana começou a praticar yoga matinal.',
        ],
      },
      {
        type: 'heading',
        content: 'Ferramentas de um "Arquiteto de Hábitos"',
      },
      {
        type: 'text',
        content: 'Mapa de Hábitos: Desenhe uma tabela com:',
      },
      {
        type: 'list',
        content: [
          'Hábito Atual | Gatilho | Recompensa | Novo Hábito Proposto',
          'Comer doces à tarde | Tédio no trabalho | Alívio momentâneo | Caminhar 10 minutos no jardim',
        ],
      },
      {
        type: 'text',
        content: 'Técnica da "Contração de Hábitos": Associe um hábito novo a um já estabelecido.',
      },
      {
        type: 'list',
        content: [
          'Exemplo: "Após escovar os dentes (hábito existente), farei 2 minutos de respiração profunda (novo hábito)".',
        ],
      },
      {
        type: 'text',
        content: 'Aplicativos Úteis:',
      },
      {
        type: 'list',
        content: [
          'Habitica: Transforma hábitos em um RPG, com recompensas virtuais.',
          'Streaks: Acompanha sequências de dias consecutivos.',
        ],
      },
      {
        type: 'heading',
        content: 'Erros Comuns (e Como Evitá-los)',
      },
      {
        type: 'list',
        content: [
          'Superestimar a Força de Vontade:',
          'Solução: Projete hábitos que dependam de ambiente e rotina, não de motivação.',
          'Ignorar a Identidade:',
          'Solução: Pergunte-se: "Que tipo de pessoa desejo me tornar?" e alinhe os hábitos a essa visão.',
          'Mudar Tudo de Uma Vez:',
          'Solução: Foque em 1-2 hábitos-chave por vez. Pequenas vitórias criam momentum.',
        ],
      },
      {
        type: 'heading',
        content: 'O Impacto dos Hábitos na Realidade',
      },
      {
        type: 'text',
        content: 'Hábitos são profecias autorrealizáveis:',
      },
      {
        type: 'list',
        content: [
          'Rotina de leitura noturna → Você se torna uma pessoa mais informada → oportunidades surgem.',
          'Prática diária de gratidão → Seu cérebro detecta mais positividade → relações melhoram.',
        ],
      },
      {
        type: 'text',
        content: 'Ou seja, o que você repete molda não apenas o que faz, mas quem você é.',
      },
      {
        type: 'heading',
        content: 'Conclusão',
      },
      {
        type: 'text',
        content:
          'Arquitetar hábitos é como desenhar o plano de uma casa onde cada cômodo reflete suas aspirações. Não se trata de força bruta, mas de design inteligente — ajustar detalhes, remover obstáculos e celebrar pequenas conquistas. Comece hoje: escolha um hábito, redesenhe seu contexto e observe como os tijolos diários constroem uma realidade nova.',
      },
      {
        type: 'heading',
        content: 'Desafio Final',
      },
      {
        type: 'text',
        content: 'Pegue um hábito que deseja mudar e responda:',
      },
      {
        type: 'list',
        content: [
          'Qual é o gatilho atual?',
          'Que recompensa ele oferece?',
          'Como você pode redesenhar o contexto para torná-lo mais alinhado aos seus objetivos?',
        ],
      },
      {
        type: 'text',
        content: 'Compartilhe suas respostas nos comentários e inspire outros leitores! 🏗️',
      },
    ],
    related: [
      {
        id: 'pomodoro',
        title: 'Técnica Pomodoro: Aumente sua Produtividade',
        image:
          'https://images.unsplash.com/photo-1434030216411-0b793f4b4173?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
      },
      {
        id: 'sono-produtividade',
        title: 'O Impacto do Sono na Produtividade',
        image:
          'https://images.unsplash.com/photo-1541781774459-bb2af2f05b55?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2060&q=80',
      },
    ],
  },
  rotinas: {
    title:
      'A Importância de Estabelecer Rotinas: Por Que Hábitos Consistentes Transformam Sua Vida',
    excerpt:
      'Descubra como rotinas bem estruturadas funcionam como um sistema operacional para o cérebro, liberando energia mental e criando espaço para o que realmente importa.',
    date: '2024-03-28',
    author: 'Dr. Lucas Mendes',
    readTime: '12 min',
    categories: ['produtividade', 'hábitos', 'desenvolvimento pessoal'],
    image:
      'https://images.unsplash.com/photo-1516321318423-f06f85e504b3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
    content: [
      {
        type: 'text',
        content:
          'Em um mundo acelerado, onde multitarefas e imprevistos dominam o cotidiano, a busca por controle e equilíbrio parece uma utopia. Mas e se a chave para uma vida mais produtiva, saudável e menos estressante estiver na simplicidade das rotinas? Mais do que uma lista de tarefas, rotinas bem estruturadas funcionam como um sistema operacional para o cérebro, liberando energia mental e criando espaço para o que realmente importa. Neste artigo, exploramos como hábitos consistentes não apenas organizam seu dia, mas remodelam seu futuro.',
      },
      {
        type: 'heading',
        content: 'O Que São Rotinas (e o Que Elas Não São)',
      },
      {
        type: 'text',
        content:
          'Rotinas são sequências intencionais de hábitos, projetadas para automatizar decisões cotidianas. Ao contrário da crença popular, elas não são sinônimo de monotonia, mas de liberdade através da estrutura.',
      },
      {
        type: 'list',
        content: [
          'Rotina ≠ Rigidez: Uma boa rotina é flexível e adaptável, como um esqueleto que sustenta o corpo, mas permite movimento.',
          'Rotina ≠ Controle total: Ela prepara você para imprevistos, reduzindo a ansiedade do "e se...?".',
        ],
      },
      {
        type: 'text',
        content:
          'Exemplo: Uma rotina matinal pode incluir 15 minutos de exercício, café da manhã nutritivo e planejamento do dia. Se algo der errado (como acordar atrasado), você ajusta o passo a passo, mas mantém a essência.',
      },
      {
        type: 'heading',
        content: 'A Ciência Por Trás das Rotinas',
      },
      {
        type: 'text',
        content: 'A neurociência explica por que rotinas são transformadoras:',
      },
      {
        type: 'list',
        content: [
          'Redução da Carga Cognitiva: O cérebro usa 20% da energia corporal. Quando você automatiza ações (como preparar o almoço ou organizar a mesa de trabalho), economiza recursos para decisões complexas.',
          'Fortalece o Córtex Pré-Frontal: Rotinas consistentes treinam a região responsável por planejamento e autocontrole, segundo estudos da Universidade Duke.',
          'Criação de "Gatilhos de Identidade": Repetir ações alinhadas a um objetivo (ex.: escrever 30 minutos diários) reforça a autopercepção ("sou um escritor"), como explica o psicólogo James Clear.',
        ],
      },
      {
        type: 'heading',
        content: '5 Benefícios das Rotinas Bem Estabelecidas',
      },
      {
        type: 'list',
        content: [
          'Redução do Estresse: Saber o que vem a seguir diminui a ansiedade. Um estudo da American Psychological Association mostrou que 60% das pessoas com rotinas relatam menor sensação de sobrecarga.',
          'Aumento da Produtividade: Eliminar microdecisões (ex.: "O que vou fazer agora?") poupa até 3 horas por dia, de acordo com pesquisa da Universidade de Columbia.',
          'Melhora da Saúde Física e Mental: Rotinas de sono regular, alimentação equilibrada e exercícios estão ligadas a menor risco de depressão e doenças crônicas.',
          'Progresso em Metas de Longo Prazo: Poupar R$ 100 por mês parece pequeno, mas em 10 anos, com juros, transforma-se em R$ 18 mil*.',
          'Resiliência em Crises: Durante períodos difíceis (como luto ou mudanças), rotinas agem como âncoras emocionais, mantendo estabilidade.',
        ],
      },
      {
        type: 'heading',
        content: 'Como Construir uma Rotina que Funciona para Você',
      },
      {
        type: 'text',
        content: 'Siga este passo a passo baseado no método S.M.A.R.T + Hábitos Atômicos:',
      },
      {
        type: 'list',
        content: [
          'Defina um Propósito Claro: Pergunte: "Qual área da minha vida quero melhorar? Saúde, carreira, relacionamentos?" Exemplo: "Quero ter mais energia para brincar com meus filhos".',
          'Comece com Hábitos "Miniaturas": Em vez de "malhar 1 hora", comece com "10 minutos de alongamento ao acordar".',
          'Use Gatilhos Contextuais: Associe o hábito a uma ação existente: "Após escovar os dentes, beberei um copo de água".',
          'Monitore e Ajuste: Use apps como Loop Habit Tracker ou um planner físico para registrar progresso.',
          'Celebre Microvitórias: Recompense-se com algo simples, como um café especial após 7 dias consistentes.',
        ],
      },
      {
        type: 'heading',
        content: 'Caso Real: A Rotina que Revolucionou uma Vida',
      },
      {
        type: 'text',
        content: 'Maria, 40 anos, mãe e empreendedora, vivia exausta até redesenhar sua rotina:',
      },
      {
        type: 'list',
        content: [
          'Antes:',
          'Sem horário fixo para dormir/acordar.',
          'Refeições desregradas (muitos delivery).',
          'Sensação constante de "não tenho tempo para mim".',
          'Depois:',
          'Rotina Noturna: 22h: Desligar telas → 22h30: Leitura leve → 23h: Dormir.',
          'Rotina Matinal: 6h: Acordar → Hidratação e alongamento → 15 minutos de planejamento do dia.',
          'Resultado em 6 meses:',
          '70% menos episódios de insônia.',
          'Aumento de 40% na produtividade no trabalho.',
        ],
      },
      {
        type: 'heading',
        content: 'Erros Comuns (e Como Corrigi-los)',
      },
      {
        type: 'list',
        content: [
          'Copiar Rotinas Alheias: Solução: Adapte modelos (como a rotina das 5h da manhã) à sua realidade.',
          'Ignorar o Ritmo Biológico: Solução: Se você é noturno, não force uma rotina matinal. Trabalhe com seu cronotipo.',
          'Superlotar a Rotina: Solução: Deixe "espaços vazios" para improviso e descanso.',
        ],
      },
      {
        type: 'heading',
        content: 'Ferramentas para Manter a Constância',
      },
      {
        type: 'list',
        content: [
          'Técnica Pomodoro: Para tarefas focadas (25 minutos de trabalho + 5 de pausa).',
          'Blocos Temáticos: Separe períodos do dia para tipos de atividade (ex.: manhã para criatividade, tarde para reuniões).',
          'Checklists Diárias: Liste 3 prioridades essenciais e risque conforme conclui.',
        ],
      },
      {
        type: 'heading',
        content: 'Conclusão',
      },
      {
        type: 'text',
        content:
          'Rotinas não são prisões, mas asas que permitem voar mais alto. Ao transformar ações repetidas em alicerces, você não só conquista metas, mas redefine quem é e o que é capaz de alcançar. Lembre-se: grandes mudanças começam com pequenos hábitos feitos de forma consistente.',
      },
      {
        type: 'heading',
        content: 'Desafio Prático',
      },
      {
        type: 'text',
        content:
          'Hoje mesmo, escolha um hábito simples (ex.: beber água ao acordar) e comprometa-se a repeti-lo por 21 dias. Anote como isso impacta seu humor, energia e clareza mental.',
      },
      {
        type: 'text',
        content:
          'E você: qual rotina deseja construir para transformar sua realidade? Compartilhe nos comentários! ⏳',
      },
      {
        type: 'text',
        content:
          '*Nota: Valores estimados consideram taxa de juros de 6% ao ano, conforme projeções do Banco Central (2023).',
      },
    ],
    related: [
      {
        id: 'arquitetura-habitos',
        title: 'Arquitetura de Hábitos: Como Rotinas Bem Projetadas Transformam Sua Realidade',
        image:
          'https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
      },
      {
        id: 'pomodoro',
        title: 'Técnica Pomodoro: Aumente sua Produtividade',
        image:
          'https://images.unsplash.com/photo-1434030216411-0b793f4b4173?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
      },
    ],
  },
  'trabalho-remoto': {
    title: 'Gestão de Tempo para Trabalho Remoto: Estratégias que Realmente Funcionam',
    excerpt:
      'Explore estratégias de gestão de tempo que realmente funcionam para profissionais que trabalham remotamente.',
    date: '2024-04-10',
    author: 'João Silva',
    readTime: '10 min',
    categories: ['Trabalho Remoto', 'Produtividade', 'Gestão de Tempo'],
    image:
      'https://images.unsplash.com/photo-1556742400-b5b7c512f9b9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
    content: [
      {
        type: 'text',
        content:
          'O trabalho remoto se tornou uma realidade para muitas pessoas nos últimos anos. Com a flexibilidade de trabalhar de qualquer lugar, vem a responsabilidade de gerenciar o tempo de forma eficaz. Neste artigo, vamos explorar estratégias de gestão de tempo que realmente funcionam para profissionais que trabalham remotamente.',
      },
      {
        type: 'heading',
        content: 'O Desafio do Trabalho Remoto',
      },
      {
        type: 'text',
        content:
          'Quando se trabalha em um escritório, é mais fácil manter a disciplina e a rotina. No entanto, quando se trabalha de casa ou de um local remoto, é fácil se distrair com coisas como TV, redes sociais, familiares ou até mesmo a própria casa. Isso pode levar a uma perda de produtividade e afetar negativamente o desempenho no trabalho.',
      },
      {
        type: 'heading',
        content: 'Estratégias de Gestão de Tempo para Trabalho Remoto',
      },
      {
        type: 'list',
        content: [
          'Defina um Horário de Trabalho: Defina um horário de trabalho claro e respeite-o. Isso inclui definir os horários de início e fim do trabalho, bem como os intervalos. Isso ajudará a manter a disciplina e evitar a procrastinação.',
          'Crie um Espaço de Trabalho Dedicado: Crie um espaço de trabalho dedicado em sua casa ou local remoto. Isso ajudará a separar o trabalho da vida pessoal e evitar distrações.',
          'Use Ferramentas de Produtividade: Use ferramentas de produtividade como aplicativos de gerenciamento de tarefas, calendários e relógios de tempo para ajudar a manter a organização e o foco.',
          'Priorize as Tarefas: Priorize as tarefas com base na importância e no prazo. Isso ajudará a garantir que as tarefas mais importantes sejam concluídas primeiro.',
          'Faça Intervalos Regulares: Faça intervalos regulares para se esticar, se mover e se refrescar. Isso ajudará a manter a energia e a concentração.',
          'Limite as Distrações: Limite as distrações como redes sociais, e-mails e mensagens instantâneas. Use ferramentas de bloqueio de sites ou aplicativos para ajudar a manter o foco.',
          'Comunique-se com a Equipe: Comunique-se com a equipe regularmente para garantir que todos estejam alinhados e trabalhando em direção aos mesmos objetivos.',
          'Aprenda a Dizer Não: Aprenda a dizer não a tarefas que não são essenciais ou que não se alinham com os objetivos da empresa.',
          'Use a Técnica Pomodoro: Use a técnica Pomodoro, que envolve trabalhar em intervalos de 25 minutos, seguidos de um intervalo de 5 minutos.',
          'Revisite e Ajuste: Revisite e ajuste regularmente as estratégias de gestão de tempo para garantir que elas estejam funcionando de forma eficaz.',
        ],
      },
      {
        type: 'heading',
        content: 'Conclusão',
      },
      {
        type: 'text',
        content:
          'A gestão de tempo é fundamental para o sucesso no trabalho remoto. Com as estratégias certas, é possível manter a produtividade e alcançar os objetivos. Lembre-se de definir um horário de trabalho, criar um espaço de trabalho dedicado, usar ferramentas de produtividade, priorizar as tarefas, fazer intervalos regulares, limitar as distrações, comunicar-se com a equipe, aprender a dizer não, usar a técnica Pomodoro e revisar e ajustar regularmente as estratégias de gestão de tempo.',
      },
    ],
    related: [
      {
        id: 'pomodoro',
        title: 'Técnica Pomodoro: Aumente sua Produtividade',
        image:
          'https://images.unsplash.com/photo-1434030216411-0b793f4b4173?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
      },
      {
        id: 'time-blocking',
        title: 'Time Blocking: Organize seu Tempo',
        image:
          'https://images.unsplash.com/photo-1506784983877-45594efa4cbe?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2068&q=80',
      },
    ],
  },
  'gerenciar-tempo': {
    title:
      '5 Maneiras Cientificamente Comprovadas de Gerenciar Melhor Seu Tempo (Sem Virar um Robô)',
    excerpt:
      'Descubra métodos baseados em neurociência e psicologia comportamental para transformar sua relação com o tempo.',
    date: '2024-04-15',
    author: 'Laura Mendes',
    readTime: '12 min',
    categories: ['Produtividade', 'Gestão de Tempo', 'Psicologia'],
    image:
      'https://images.unsplash.com/photo-1506784983877-45594efa4cbe?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2068&q=80',
    content: [
      {
        type: 'text',
        content:
          'Você já terminou o dia com a sensação de que trabalhou 12 horas, mas não concluiu nada relevante? A culpa não é da sua falta de esforço, mas de como você projeta seu tempo. Gerenciar horas não é sobre encaixar mais tarefas, mas sobre escolher o que importa e executar com inteligência. Neste artigo, você descobrirá métodos baseados em neurociência e psicologia comportamental que vão além das dicas clichês. Prepare-se para transformar sua relação com o relógio!',
      },
      {
        type: 'heading',
        content: 'Priorização Baseada em Impacto (Não Urgência)',
      },
      {
        type: 'text',
        content:
          'A famosa Matriz de Eisenhower divide tarefas em urgentes e importantes, mas aqui vamos além: classifique-as pelo impacto a longo prazo.',
      },
      {
        type: 'list',
        content: [
          'Pergunte: "Se eu fizer apenas UMA coisa hoje, qual terá o maior efeito daqui a 6 meses?"',
          'Use a regra 80/20: 20% das tarefas geram 80% dos resultados.',
          'Exemplo prático: Tarefa comum: Responder e-mails (urgente, mas pouco impactante). Tarefa priorizada: Escrever um capítulo do seu livro (não urgente, mas transformador).',
          'Ferramenta: App Todoist com tags de "impacto alto/médio/baixo".',
          'Dado científico: Um estudo da Universidade de Harvard mostrou que profissionais que focam em atividades de alto impacto são promovidos 2x mais rápido.',
        ],
      },
      {
        type: 'heading',
        content: 'Batch Working: O Poder do Agrupamento',
      },
      {
        type: 'text',
        content:
          'Alternar entre tarefas diferentes consome até 40% do seu tempo (pesquisa da Universidade da Califórnia). A solução é o agrupamento de atividades similares.',
      },
      {
        type: 'list',
        content: [
          'Passo a passo: Separe tarechas por tipo (ex.: criativas, administrativas). Reserve blocos específicos para cada grupo (ex.: "Terça-feira: só reuniões").',
          'Exemplo real: Carlos, designer freelancer, reduziu o tempo de entrega de projetos em 30% ao agrupar: Manhãs: Design (alta criatividade). Tardes: Clientes e burocracia.',
          'Ferramenta: Clockify para medir quanto tempo cada tipo de tarefa consome.',
        ],
      },
      {
        type: 'heading',
        content: 'A Técnica do "Não Agora" para Distrações',
      },
      {
        type: 'text',
        content:
          'Checar redes sociais ou resolver pequenas demandas aleatórias quebram seu fluxo. Em vez de dizer "não", diga "não agora".',
      },
      {
        type: 'list',
        content: [
          'Como aplicar: Tenha um bloco de notas digital (ex.: Evernote) para anotar interrupções. Revise essas anotações apenas em horários pré-definidos (ex.: 12h e 17h).',
          'Por que funciona: O cérebro se acalma ao saber que a ideia não será perdida, reduzindo a ansiedade de multitarefas.',
        ],
      },
      {
        type: 'heading',
        content: 'Rituais de Transição Entre Modos',
      },
      {
        type: 'text',
        content:
          'Seu cérebro não é uma máquina de ligar/desligar. Rituais ajudam a alternar entre "modo trabalho" e "modo descanso" sem estresse.',
      },
      {
        type: 'list',
        content: [
          'Rituais eficazes: Início do trabalho: Ligar uma luminária específica + preparar um chá. Fim do expediente: Fechar a aba do e-mail + escrever 3 conquistas do dia.',
          'Base científica: Rituais ativam o córtex pré-frontal, sinalizando que é hora de mudar de estado mental (estudo publicado na Nature).',
        ],
      },
      {
        type: 'heading',
        content: 'Auditoria de Tempo Semanal (Onde Você Está Vazando Horas?)',
      },
      {
        type: 'text',
        content:
          'Você não pode melhorar o que não mede. Faça uma auditoria para descobrir para onde seu tempo está indo.',
      },
      {
        type: 'list',
        content: [
          'Como fazer: Registre todas as atividades por 7 dias (use o app Toggl Track). Categorize-as em: Essenciais (trabalho, família, saúde). Vazamentos (rolagem sem propósito, tarefas delegáveis).',
          'Exemplo de insight: Ana, gerente de marketing, descobriu que gastava 6h semanais em reuniões desnecessárias. Automatizou 80% delas com relatórios em vídeo.',
        ],
      },
      {
        type: 'heading',
        content: 'Conclusão',
      },
      {
        type: 'text',
        content:
          'Gerenciar tempo não é sobre controle absoluto, mas sobre direcionar atenção ao que transforma sua vida. Experimente uma das técnicas por 7 dias e observe como pequenos ajustes geram grandes resultados. Lembre-se: o objetivo não é fazer tudo, mas fazer o que importa — com menos culpa e mais presença.',
      },
      {
        type: 'heading',
        content: 'Desafio dos 7 Dias',
      },
      {
        type: 'text',
        content:
          'Escolha uma técnica, implemente-a diariamente e anote: Quantas horas recuperou? Como isso impactou seu bem-estar? Compartilhe suas descobertas nos comentários! ⏰',
      },
    ],
    related: [
      {
        id: 'pomodoro',
        title: 'Técnica Pomodoro: Aumente sua Produtividade',
        image:
          'https://images.unsplash.com/photo-1434030216411-0b793f4b4173?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
      },
      {
        id: 'time-blocking',
        title: 'Time Blocking: Organize seu Tempo',
        image:
          'https://images.unsplash.com/photo-1506784983877-45594efa4cbe?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2068&q=80',
      },
    ],
  },
  cronotipos: {
    title: 'Entenda seu Cronotipo: Otimize seu Dia com Base no seu Relógio Biológico',
    excerpt: 'Descubra como entender seu cronotipo pode melhorar sua produtividade e bem-estar.',
    date: '2024-04-20',
    author: 'Fernanda Almeida',
    readTime: '10 min',
    categories: ['Produtividade', 'Saúde', 'Bem-estar'],
    image:
      'https://images.unsplash.com/photo-1506784983877-45594efa4cbe?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2068&q=80',
    content: [
      {
        type: 'text',
        content:
          'Você já se sentiu como se seu corpo tivesse um relógio interno que ditasse quando você está mais alerta ou mais cansado? Isso não é apenas uma sensação - é um fenômeno real chamado de cronotipo. Neste artigo, vamos explorar o que é o cronotipo, como ele afeta sua vida diária e como você pode otimizá-lo para melhorar sua produtividade e bem-estar.',
      },
      {
        type: 'heading',
        content: 'O que é o Cronotipo?',
      },
      {
        type: 'text',
        content:
          'O cronotipo é o ritmo natural do seu corpo, que é influenciado pelo seu relógio biológico. Ele é determinado pela combinação de fatores genéticos e ambientais, e pode variar de pessoa para pessoa. Existem três principais cronotipos:',
      },
      {
        type: 'list',
        content: [
          'Matutino: Pessoas que são mais alertas e produtivas pela manhã, geralmente acordam cedo e se sentem mais energizadas durante o dia.',
          'Vespertino: Pessoas que são mais alertas e produtivas à noite, geralmente se sentem mais energizadas após o pôr do sol e preferem trabalhar ou estudar à noite.',
          'Intermediário: Pessoas que não se encaixam em nenhum dos dois cronotipos anteriores, geralmente têm um ritmo mais flexível e podem se adaptar a diferentes horários.',
        ],
      },
      {
        type: 'heading',
        content: 'Como o Cronotipo Afeta sua Vida Diária',
      },
      {
        type: 'text',
        content: 'O cronotipo pode afetar sua vida diária de várias maneiras, incluindo:',
      },
      {
        type: 'list',
        content: [
          'Produtividade: Se você trabalha ou estuda em um horário que não é compatível com seu cronotipo, pode se sentir mais cansado ou menos produtivo.',
          'Sono: O cronotipo pode influenciar a qualidade do seu sono, com pessoas que são mais matutinas tendendo a dormir mais cedo e pessoas que são mais vespertinas tendendo a dormir mais tarde.',
          'Saúde: O cronotipo também pode afetar sua saúde, com estudos sugerindo que pessoas que são mais matutinas tendem a ter menos problemas de saúde do que pessoas que são mais vespertinas.',
        ],
      },
      {
        type: 'heading',
        content: 'Como Otimizar seu Cronotipo',
      },
      {
        type: 'list',
        content: [
          'Identifique seu Cronotipo: Faça um teste para descobrir qual é seu cronotipo e ajuste seu horário de acordo.',
          'Ajuste seu Horário: Se você é mais matutino, tente acordar mais cedo e fazer as coisas mais importantes do dia pela manhã. Se você é mais vespertino, tente trabalhar ou estudar à noite.',
          'Use seu Relógio Biológico: Use seu relógio biológico para planejar seu dia e fazer as coisas que você precisa fazer quando você está mais alerta e produtivo.',
          'Priorize o Sono: Priorize o sono e tente dormir em um horário que seja compatível com seu cronotipo.',
        ],
      },
      {
        type: 'heading',
        content: 'Conclusão',
      },
      {
        type: 'text',
        content:
          'Entender seu cronotipo pode ser uma ferramenta poderosa para melhorar sua produtividade e bem-estar. Ao identificar seu cronotipo e ajustar seu horário de acordo, você pode se sentir mais alerta, mais produtivo e mais saudável. Lembre-se de que o cronotipo é único para cada pessoa, então é importante encontrar o que funciona melhor para você.',
      },
    ],
    related: [
      {
        id: 'sono-produtividade',
        title: 'O Impacto do Sono na Produtividade',
        image:
          'https://images.unsplash.com/photo-1541781774459-bb2af2f05b55?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2060&q=80',
      },
      {
        id: 'rotinas',
        title: 'A Importância de Estabelecer Rotinas',
        image:
          'https://images.unsplash.com/photo-1516321318423-f06f85e504b3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
      },
    ],
  },
  mindfulness: {
    title: 'Mindfulness e Produtividade: Como a Atenção Plena Transforma sua Eficiência',
    excerpt:
      'Descubra como o mindfulness pode transformar sua eficiência e melhorar sua produtividade.',
    date: '2024-04-25',
    author: 'Gabriela Santos',
    readTime: '10 min',
    categories: ['Produtividade', 'Eficiência', 'Mindfulness', 'Saúde Mental'],
    image:
      'https://images.unsplash.com/photo-1556742400-b5b7c512f9b9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
    content: [
      {
        type: 'text',
        content:
          'No mundo atual, onde a velocidade e a eficiência são fundamentais para o sucesso, muitas pessoas buscam maneiras de melhorar sua produtividade. Uma das práticas que tem ganhado popularidade nos últimos anos é o mindfulness, ou atenção plena. Neste artigo, vamos explorar como o mindfulness pode transformar sua eficiência e melhorar sua produtividade.',
      },
      {
        type: 'heading',
        content: 'O que é Mindfulness?',
      },
      {
        type: 'text',
        content:
          'Mindfulness é a prática de estar presente no momento, sem julgamentos ou distrações. É a capacidade de se concentrar no que está acontecendo no presente, sem se deixar levar por pensamentos ou emoções. A prática do mindfulness pode ser feita através de meditação, respiração profunda, ou simplesmente prestando atenção ao que está acontecendo ao seu redor.',
      },
      {
        type: 'heading',
        content: 'Como o Mindfulness Afeta a Produtividade',
      },
      {
        type: 'list',
        content: [
          'Melhora a Concentração: Ao praticar o mindfulness, você pode melhorar sua capacidade de se concentrar em uma tarefa específica, sem se deixar distrair por pensamentos ou emoções.',
          'Reduz o Estresse: O mindfulness pode ajudar a reduzir o estresse e a ansiedade, o que pode melhorar sua produtividade e eficiência.',
          'Aumenta a Criatividade: A prática do mindfulness pode ajudar a aumentar a criatividade e a inovação, o que pode ser fundamental para resolver problemas e encontrar soluções.',
          'Melhora a Tomada de Decisões: O mindfulness pode ajudar a melhorar a tomada de decisões, ao permitir que você se concentre nos fatos e nas opções, sem se deixar influenciar por emoções ou preconceitos.',
        ],
      },
      {
        type: 'heading',
        content: 'Como Praticar o Mindfulness para Melhorar a Produtividade',
      },
      {
        type: 'list',
        content: [
          'Comece com a Respiração: Comece com a respiração profunda e consciente, para ajudar a se concentrar e a se acalmar.',
          'Pratique a Meditação: Pratique a meditação regularmente, para ajudar a melhorar a concentração e a reduzir o estresse.',
          'Seja Presente: Seja presente no momento, sem se deixar levar por pensamentos ou emoções.',
          'Priorize as Tarefas: Priorize as tarefas e se concentre em uma tarefa específica, sem se deixar distrair por outras coisas.',
        ],
      },
      {
        type: 'heading',
        content: 'Benefícios do Mindfulness para a Produtividade',
      },
      {
        type: 'list',
        content: [
          'Melhoria da Concentração: A prática do mindfulness pode melhorar a concentração e a capacidade de se concentrar em uma tarefa específica.',
          'Redução do Estresse: O mindfulness pode ajudar a reduzir o estresse e a ansiedade, o que pode melhorar a produtividade e a eficiência.',
          'Aumento da Criatividade: A prática do mindfulness pode ajudar a aumentar a criatividade e a inovação.',
          'Melhoria da Tomada de Decisões: O mindfulness pode ajudar a melhorar a tomada de decisões, ao permitir que você se concentre nos fatos e nas opções.',
        ],
      },
      {
        type: 'heading',
        content: 'Conclusão',
      },
      {
        type: 'text',
        content:
          'O mindfulness é uma prática poderosa que pode transformar sua eficiência e melhorar sua produtividade. Ao praticar o mindfulness, você pode melhorar a concentração, reduzir o estresse, aumentar a criatividade e melhorar a tomada de decisões. Lembre-se de que a prática do mindfulness é simples e pode ser feita em qualquer lugar, a qualquer hora.',
      },
    ],
    related: [
      {
        id: 'gerenciar-tempo',
        title: '5 Maneiras Cientificamente Comprovadas de Gerenciar Melhor Seu Tempo',
        image:
          'https://images.unsplash.com/photo-1506784983877-45594efa4cbe?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2068&q=80',
      },
      {
        id: 'arquitetura-habitos',
        title: 'Arquitetura de Hábitos: Como Rotinas Bem Projetadas Transformam Sua Realidade',
        image:
          'https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
      },
    ],
  },
  'higiene-sono': {
    title: 'Higiene do Sono: O Guia Completo para Melhorar a Qualidade do Sono',
    excerpt:
      'Descubra práticas e hábitos essenciais para melhorar a qualidade do seu sono e garantir noites mais reparadoras.',
    date: '2024-04-30',
    author: 'Dr. Marcelo Santos',
    readTime: '8 min',
    categories: ['Saúde', 'Bem-estar', 'Sono'],
    image:
      'https://images.unsplash.com/photo-1520206183501-b80df61043c2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2071&q=80',
    content: [
      {
        type: 'text',
        content:
          'A higiene do sono é um conjunto de práticas e hábitos que ajudam a melhorar a qualidade do sono. É fundamental para a saúde física e mental, pois o sono é essencial para a recuperação e o rejuvenescimento do corpo. Neste artigo, vamos explorar as melhores práticas para melhorar a higiene do sono e ter um sono mais profundo e reparador.',
      },
      {
        type: 'heading',
        content: 'Por que a Higiene do Sono é Importante?',
      },
      {
        type: 'text',
        content:
          'A higiene do sono é importante porque o sono é essencial para a saúde. Durante o sono, o corpo recupera e rejuvenesce, e a falta de sono pode levar a problemas de saúde, como:',
      },
      {
        type: 'list',
        content: [
          'Fadiga e cansaço',
          'Problemas de concentração e memória',
          'Doenças cardíacas e diabetes',
          'Problemas de humor e ansiedade',
        ],
      },
      {
        type: 'heading',
        content: 'Como Melhorar a Higiene do Sono?',
      },
      {
        type: 'text',
        content: 'Aqui estão algumas dicas práticas para melhorar a higiene do sono:',
      },
      {
        type: 'list',
        content: [
          'Estabeleça uma Rotina de Sono: Estabeleça uma rotina de sono regular, incluindo o horário de dormir e acordar.',
          'Crie um Ambiente Propício para o Sono: Certifique-se de que o quarto esteja escuro, silencioso e fresco.',
          'Evite a Exposição à Luz Azul: Evite a exposição à luz azul de dispositivos eletrônicos antes de dormir.',
          'Faça Exercícios Regularmente: Faça exercícios regularmente, mas evite fazer exercícios intensos antes de dormir.',
          'Evite a Cafeína e o Álcool: Evite a cafeína e o álcool antes de dormir.',
          'Tente Relaxar antes de Dormir: Tente relaxar antes de dormir, com técnicas de relaxamento, como meditação ou respiração profunda.',
          'Evite a Uso de Dispositivos Eletrônicos no Quarto: Evite o uso de dispositivos eletrônicos no quarto, incluindo celulares e tablets.',
          'Certifique-se de que a Cama seja Confortável: Certifique-se de que a cama seja confortável e que o colchão seja adequado.',
          'Tente Dormir em um Ambiente Silencioso: Tente dormir em um ambiente silencioso, com o uso de tampões de ouvido ou uma máquina de ruído branco.',
          'Consulte um Médico se Necessário: Consulte um médico se você tiver problemas de sono persistentes.',
        ],
      },
      {
        type: 'heading',
        content: 'Perguntas Frequentes sobre Higiene do Sono',
      },
      {
        type: 'text',
        content: 'Aqui estão algumas perguntas frequentes sobre higiene do sono:',
      },
      {
        type: 'heading',
        content: 'Quantas horas de sono são necessárias por noite?',
      },
      {
        type: 'text',
        content:
          'A quantidade de sono necessária varia de pessoa para pessoa, mas a maioria dos adultos precisa de 7-9 horas de sono por noite.',
      },
      {
        type: 'heading',
        content: 'Como posso melhorar a qualidade do meu sono?',
      },
      {
        type: 'text',
        content:
          'Você pode melhorar a qualidade do seu sono estabelecendo uma rotina de sono regular, criando um ambiente propício para o sono e evitando a exposição à luz azul antes de dormir.',
      },
      {
        type: 'heading',
        content: 'O que é a síndrome da apneia do sono?',
      },
      {
        type: 'text',
        content:
          'A síndrome da apneia do sono é uma condição em que a pessoa para de respirar por períodos curtos durante o sono, o que pode levar a problemas de saúde.',
      },
      {
        type: 'heading',
        content: 'Conclusão',
      },
      {
        type: 'text',
        content:
          'A higiene do sono é fundamental para a saúde física e mental. Com as dicas práticas apresentadas neste artigo, você pode melhorar a qualidade do seu sono e ter um sono mais profundo e reparador. Lembre-se de estabelecer uma rotina de sono regular, criar um ambiente propício para o sono e evitar a exposição à luz azul antes de dormir. Se você tiver problemas de sono persistentes, consulte um médico para obter ajuda.',
      },
    ],
    related: [
      {
        id: 'sono-produtividade',
        title: 'O Impacto do Sono na Produtividade',
        image:
          'https://images.unsplash.com/photo-1541781774459-bb2af2f05b55?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2060&q=80',
      },
      {
        id: 'cronotipos',
        title: 'Entenda seu Cronotipo: Otimize seu Dia com Base no seu Relógio Biológico',
        image:
          'https://images.unsplash.com/photo-1506784983877-45594efa4cbe?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2068&q=80',
      },
    ],
  },
  home: {
    title: 'Bem-vindo ao Blog de Produtividade e Bem-estar',
    excerpt:
      'Explore artigos sobre produtividade, gestão de tempo, saúde e desenvolvimento pessoal para transformar sua vida.',
    date: '2025-03-20',
    author: 'Equipe Editorial',
    readTime: '5 min',
    categories: ['Produtividade', 'Bem-estar', 'Desenvolvimento Pessoal'],
    image:
      'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2071&q=80',
    content: [
      {
        type: 'banner',
        content: {
          title: 'Transforme Sua Vida com Produtividade e Bem-estar',
          subtitle:
            'Descubra métodos comprovados para otimizar seu tempo e melhorar sua qualidade de vida',
          image:
            'https://images.unsplash.com/photo-1499750310107-5fef28a66643?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
          cta: {
            text: 'Comece Agora',
            link: '#artigos-destaque',
          },
          highlights: [
            'Técnicas de Produtividade',
            'Gestão de Tempo',
            'Saúde Mental',
            'Desenvolvimento Pessoal',
          ],
        },
      },
      {
        type: 'text',
        content:
          'Bem-vindo ao nosso blog dedicado a ajudar você a alcançar mais produtividade e bem-estar em sua vida. Aqui você encontrará artigos cuidadosamente elaborados sobre diversos temas que podem transformar sua maneira de trabalhar, viver e se desenvolver.',
      },
      {
        type: 'heading',
        content: 'O Que Você Encontrará Aqui',
      },
      {
        type: 'text',
        content:
          'Nosso blog é organizado em diferentes categorias para facilitar sua navegação e encontrar exatamente o que você procura:',
      },
      {
        type: 'list',
        content: [
          'Produtividade: Técnicas e métodos para otimizar seu tempo e aumentar sua eficiência',
          'Gestão de Tempo: Estratégias para organizar melhor suas atividades e prioridades',
          'Saúde e Bem-estar: Dicas para manter um estilo de vida saudável e equilibrado',
          'Desenvolvimento Pessoal: Insights para crescimento pessoal e profissional',
        ],
      },
      {
        type: 'heading',
        content: 'Artigos Recentes',
      },
      {
        type: 'articles-grid',
        content: [
          {
            id: 'higiene-sono',
            title: 'Higiene do Sono: O Guia Completo',
            date: '2025-03-18',
            image:
              'https://images.unsplash.com/photo-1520206183501-b80df61043c2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2071&q=80',
          },
          {
            id: 'mindfulness',
            title: 'Mindfulness e Produtividade',
            date: '2025-03-12',
            image:
              'https://images.unsplash.com/photo-1556742400-b5b7c512f9b9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
          },
          {
            id: 'cronotipos',
            title: 'Entenda seu Cronotipo',
            date: '2025-03-02',
            image:
              'https://images.unsplash.com/photo-1506784983877-45594efa4cbe?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2068&q=80',
          },
          {
            id: 'gerenciar-tempo',
            title: '5 Maneiras de Gerenciar Melhor Seu Tempo',
            date: '2025-02-28',
            image:
              'https://images.unsplash.com/photo-1506784983877-45594efa4cbe?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2068&q=80',
          },
          {
            id: 'trabalho-remoto',
            title: 'Gestão de Tempo para Trabalho Remoto',
            date: '2025-02-22',
            image:
              'https://images.unsplash.com/photo-1556742400-b5b7c512f9b9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
          },
        ],
      },
      {
        type: 'heading',
        content: 'Artigos em Destaque',
      },
      {
        type: 'text',
        content: 'Confira alguns dos nossos artigos mais populares:',
      },
      {
        type: 'list',
        content: [
          'Técnica Pomodoro: Como gerenciar seu tempo de forma eficiente',
          'Arquitetura de Hábitos: Construindo rotinas que transformam sua realidade',
          'O Impacto do Sono na Produtividade: A importância de um bom descanso',
          'Time Blocking: Organize seu tempo de maneira estratégica',
        ],
      },
      {
        type: 'heading',
        content: 'Nossa Missão',
      },
      {
        type: 'text',
        content:
          'Nossa missão é fornecer conteúdo de qualidade, baseado em pesquisas e experiências práticas, para ajudar você a desenvolver hábitos mais saudáveis e produtivos. Acreditamos que pequenas mudanças diárias podem levar a grandes transformações na vida.',
      },
      {
        type: 'heading',
        content: 'Participe da Nossa Comunidade',
      },
      {
        type: 'text',
        content:
          'Convidamos você a fazer parte da nossa comunidade. Comente nos artigos, compartilhe suas experiências e conecte-se com outros leitores que também estão em busca de desenvolvimento pessoal e profissional.',
      },
      {
        type: 'heading',
        content: 'Comece Sua Jornada',
      },
      {
        type: 'text',
        content:
          'Explore nossos artigos, escolha um tema que ressoe com você e comece sua jornada de transformação hoje mesmo. Cada artigo foi criado pensando em oferecer valor prático e insights que você pode aplicar imediatamente em sua vida.',
      },
    ],
    related: [
      {
        id: 'higiene-sono',
        title: 'Higiene do Sono: O Guia Completo para Melhorar a Qualidade do Sono',
        image:
          'https://images.unsplash.com/photo-1520206183501-b80df61043c2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2071&q=80',
      },
      {
        id: 'pomodoro',
        title: 'Técnica Pomodoro: Aumente sua Produtividade',
        image:
          'https://images.unsplash.com/photo-1434030216411-0b793f4b4173?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
      },
      {
        id: 'arquitetura-habitos',
        title: 'Arquitetura de Hábitos: Como Rotinas Bem Projetadas Transformam Sua Realidade',
        image:
          'https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
      },
    ],
  },
};
