import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { BRAZILIAN_CITIES } from '../constants/brazilianCities';
import { fetchWeatherByCity } from '../constants/weatherConfig';
import {
  FaSun,
  FaCloudSun,
  FaCloud,
  FaCloudRain,
  FaCloudShowersHeavy,
  FaArrowLeft,
  FaWind,
  FaTemperatureHigh,
  FaTint,
} from 'react-icons/fa';

const WeatherDetail = () => {
  const { cityName } = useParams();
  const [city, setCity] = useState(null);
  const [weatherData, setWeatherData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Encontrar a cidade pelo nome na URL
    const foundCity = BRAZILIAN_CITIES.find(
      c => c.name.toLowerCase() === decodeURIComponent(cityName).toLowerCase()
    );

    if (foundCity) {
      setCity(foundCity);

      // Buscar dados reais da API
      const fetchData = async () => {
        try {
          const data = await fetchWeatherByCity(`${foundCity.name},${foundCity.state},Brazil`);
          setWeatherData(data);
          setLoading(false);
        } catch (err) {
          setError('Erro ao buscar dados do clima. Tente novamente mais tarde.');
          setLoading(false);
        }
      };

      fetchData();
    } else {
      setError('Cidade não encontrada');
      setLoading(false);
    }

    // Scrollar para o topo quando carregar a página
    window.scrollTo(0, 0);
  }, [cityName]);

  // Função auxiliar para obter o nome do dia
  const getDayName = dateStr => {
    const days = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
    const date = new Date(dateStr);
    return days[date.getDay()];
  };

  // Função para obter o ícone com base no código de condição climática
  const getWeatherIcon = code => {
    // Usando os códigos da API Weather
    // https://www.weatherapi.com/docs/weather_conditions.json
    if (code >= 1000 && code <= 1003) {
      return <FaSun className="text-yellow-500" size={28} />;
    } else if (code >= 1004 && code <= 1009) {
      return <FaCloudSun className="text-yellow-400" size={28} />;
    } else if (code >= 1010 && code <= 1030) {
      return <FaCloud className="text-gray-400" size={28} />;
    } else if (code >= 1063 && code <= 1087) {
      return <FaCloudRain className="text-blue-400" size={28} />;
    } else if (code >= 1114 && code <= 1282) {
      return <FaCloudShowersHeavy className="text-blue-600" size={28} />;
    } else {
      return <FaCloudSun className="text-yellow-400" size={28} />;
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
        <div className="text-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-primary-600 mx-auto mb-4"></div>
          <p className="text-xl text-gray-600 dark:text-gray-300">
            Carregando previsão do tempo...
          </p>
        </div>
      </div>
    );
  }

  if (error || !city) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
        <div className="text-center max-w-md mx-auto px-4">
          <h1 className="text-2xl font-bold text-red-600 mb-4">Erro</h1>
          <p className="text-lg text-gray-600 dark:text-gray-300 mb-6">
            {error || 'Não foi possível encontrar informações para esta cidade.'}
          </p>
          <Link
            to="/clima"
            className="inline-flex items-center px-6 py-3 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors"
          >
            <FaArrowLeft className="mr-2" /> Voltar para a lista de cidades
          </Link>
        </div>
      </div>
    );
  }

  if (!weatherData) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
        <div className="text-center max-w-md mx-auto px-4">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-primary-600 mx-auto mb-4"></div>
          <p className="text-lg text-gray-600 dark:text-gray-300 mb-6">
            Buscando dados meteorológicos...
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="mb-8">
          <Link
            to="/clima"
            className="inline-flex items-center text-primary-600 dark:text-primary-400 hover:underline mb-4"
          >
            <FaArrowLeft className="mr-2" /> Voltar para todas as cidades
          </Link>

          <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-2">
            <h1 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white">
              {city.name}, {city.state}
            </h1>
            <div className="mt-2 md:mt-0 flex items-center text-gray-500 dark:text-gray-400">
              <span>
                Atualizado: {new Date().toLocaleTimeString()}
              </span>
            </div>
          </div>
          <p className="text-xl text-gray-600 dark:text-gray-400">Previsão detalhada do tempo</p>
        </div>

        <div className="animate-fadeIn">
          <div className="bg-gradient-to-r from-blue-100 to-blue-50 dark:from-gray-700 dark:to-gray-800 rounded-xl p-8 mb-10 shadow-lg">
            <div className="flex flex-col md:flex-row justify-between items-center">
              <div className="mb-6 md:mb-0 text-center md:text-left">
                <p className="text-6xl font-bold text-gray-900 dark:text-white">
                  {weatherData.current.temp_c}°C
                </p>
                <p className="text-2xl text-gray-700 dark:text-gray-300 mt-2">
                  {weatherData.current.condition.text}
                </p>
                <p className="text-gray-600 dark:text-gray-400 mt-1">
                  Atualizado em {new Date(weatherData.current.last_updated).toLocaleTimeString()}
                </p>
              </div>
              <div className="text-7xl">
                <img
                  src={weatherData.current.condition.icon}
                  alt={weatherData.current.condition.text}
                  className="w-24 h-24"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-8">
              <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-sm">
                <div className="flex items-center">
                  <FaTint className="text-blue-500 mr-2" />
                  <p className="text-gray-500 dark:text-gray-400 mb-1">Umidade</p>
                </div>
                <p className="text-2xl font-semibold text-gray-900 dark:text-white">
                  {weatherData.current.humidity}%
                </p>
              </div>
              <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-sm">
                <div className="flex items-center">
                  <FaWind className="text-blue-400 mr-2" />
                  <p className="text-gray-500 dark:text-gray-400 mb-1">Velocidade do vento</p>
                </div>
                <p className="text-2xl font-semibold text-gray-900 dark:text-white">
                  {weatherData.current.wind_kph} km/h
                </p>
              </div>
              <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-sm">
                <div className="flex items-center">
                  <FaTemperatureHigh className="text-red-500 mr-2" />
                  <p className="text-gray-500 dark:text-gray-400 mb-1">Sensação térmica</p>
                </div>
                <p className="text-2xl font-semibold text-gray-900 dark:text-white">
                  {weatherData.current.feelslike_c}°C
                </p>
              </div>
            </div>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
              Previsão para os próximos dias
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
              {weatherData.forecast.forecastday.map((day, index) => (
                <div
                  key={index}
                  className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow"
                >
                  <p className="font-medium text-lg text-gray-900 dark:text-white mb-4">
                    {getDayName(day.date)}
                  </p>
                  <div className="my-4 flex justify-center">
                    <img
                      src={day.day.condition.icon}
                      alt={day.day.condition.text}
                      className="w-16 h-16"
                    />
                  </div>
                  <p className="text-gray-600 dark:text-gray-400 mb-4 text-center">{day.day.condition.text}</p>
                  <div className="flex justify-between items-center text-lg font-semibold">
                    <span className="text-blue-600 dark:text-blue-400">{day.day.mintemp_c.toFixed(0)}°</span>
                    <span className="text-gray-500 dark:text-gray-400">|</span>
                    <span className="text-red-600 dark:text-red-400">{day.day.maxtemp_c.toFixed(0)}°</span>
                  </div>

                  <div className="mt-4 pt-4 border-t border-gray-200 dark:border-gray-700">
                    <div className="grid grid-cols-2 gap-2 text-sm">
                      <div>
                        <p className="text-gray-500 dark:text-gray-400">Vento</p>
                        <p className="font-medium text-gray-900 dark:text-white">{day.day.maxwind_kph} km/h</p>
                      </div>
                      <div>
                        <p className="text-gray-500 dark:text-gray-400">Chuva</p>
                        <p className="font-medium text-gray-900 dark:text-white">{day.day.daily_chance_of_rain}%</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-md mb-8">
            <h2 className="text-xl font-bold text-gray-900 dark:text-white mb-4">
              Detalhes adicionais
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <p className="text-gray-500 dark:text-gray-400">Índice UV</p>
                <p className="font-medium text-gray-900 dark:text-white">{weatherData.current.uv}</p>
              </div>
              <div>
                <p className="text-gray-500 dark:text-gray-400">Pressão</p>
                <p className="font-medium text-gray-900 dark:text-white">{weatherData.current.pressure_mb} mb</p>
              </div>
              <div>
                <p className="text-gray-500 dark:text-gray-400">Visibilidade</p>
                <p className="font-medium text-gray-900 dark:text-white">{weatherData.current.vis_km} km</p>
              </div>
              <div>
                <p className="text-gray-500 dark:text-gray-400">Precipitação</p>
                <p className="font-medium text-gray-900 dark:text-white">{weatherData.current.precip_mm} mm</p>
              </div>
            </div>
          </div>

          <div className="text-center text-gray-500 dark:text-gray-400 text-sm border-t border-gray-200 dark:border-gray-700 pt-6">
            <p>Dados fornecidos por WeatherAPI.com</p>
            <p>
              Última atualização: {new Date(weatherData.current.last_updated).toLocaleDateString()} às{' '}
              {new Date(weatherData.current.last_updated).toLocaleTimeString()}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeatherDetail;
