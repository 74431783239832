import React, { useState } from 'react';

function Alarm({ alarms, onAdd, onToggle, onDelete }) {
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [seconds, setSeconds] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    const h = parseInt(hours) || 0;
    const m = parseInt(minutes) || 0;
    const s = parseInt(seconds) || 0;

    if (h >= 0 && h < 24 && m >= 0 && m < 60 && s >= 0 && s < 60) {
      onAdd(h, m, s);
      setHours('');
      setMinutes('');
      setSeconds('');
    }
  };

  return (
    <div className="space-y-6">
      {/* Add Alarm Form */}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex justify-center space-x-4">
          <div className="flex flex-col items-center">
            <input
              type="number"
              min="0"
              max="23"
              value={hours}
              onChange={e => setHours(e.target.value)}
              placeholder="00"
              className="w-20 text-2xl font-bold text-center bg-transparent border-b-2 border-gray-300 focus:border-blue-500 focus:outline-none dark:border-gray-600 dark:focus:border-blue-400 dark:text-white"
            />
            <span className="text-sm text-gray-500 dark:text-gray-400">Horas</span>
          </div>
          <span className="text-2xl font-bold text-gray-900 dark:text-white">:</span>
          <div className="flex flex-col items-center">
            <input
              type="number"
              min="0"
              max="59"
              value={minutes}
              onChange={e => setMinutes(e.target.value)}
              placeholder="00"
              className="w-20 text-2xl font-bold text-center bg-transparent border-b-2 border-gray-300 focus:border-blue-500 focus:outline-none dark:border-gray-600 dark:focus:border-blue-400 dark:text-white"
            />
            <span className="text-sm text-gray-500 dark:text-gray-400">Minutos</span>
          </div>
          <span className="text-2xl font-bold text-gray-900 dark:text-white">:</span>
          <div className="flex flex-col items-center">
            <input
              type="number"
              min="0"
              max="59"
              value={seconds}
              onChange={e => setSeconds(e.target.value)}
              placeholder="00"
              className="w-20 text-2xl font-bold text-center bg-transparent border-b-2 border-gray-300 focus:border-blue-500 focus:outline-none dark:border-gray-600 dark:focus:border-blue-400 dark:text-white"
            />
            <span className="text-sm text-gray-500 dark:text-gray-400">Segundos</span>
          </div>
        </div>
        <div className="flex justify-center">
          <button
            type="submit"
            className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-200"
          >
            Adicionar Alarme
          </button>
        </div>
      </form>

      {/* Alarms List */}
      {alarms.length > 0 && (
        <div className="mt-8">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
            Alarmes Configurados
          </h3>
          <div className="space-y-2">
            {alarms.map(alarm => (
              <div
                key={alarm.id}
                className="flex justify-between items-center p-4 bg-gray-50 dark:bg-gray-700 rounded-lg"
              >
                <div className="flex items-center space-x-4">
                  <button
                    onClick={() => onToggle(alarm.id)}
                    className={`w-8 h-8 rounded-full flex items-center justify-center ${
                      alarm.active
                        ? 'bg-green-500 hover:bg-green-600'
                        : 'bg-gray-300 hover:bg-gray-400 dark:bg-gray-600 dark:hover:bg-gray-500'
                    }`}
                  >
                    <span className="sr-only">{alarm.active ? 'Desativar' : 'Ativar'}</span>
                  </button>
                  <span className="text-lg font-medium text-gray-900 dark:text-white">
                    {alarm.hours.toString().padStart(2, '0')}:
                    {alarm.minutes.toString().padStart(2, '0')}:
                    {alarm.seconds.toString().padStart(2, '0')}
                  </span>
                </div>
                <button
                  onClick={() => onDelete(alarm.id)}
                  className="text-red-500 hover:text-red-600 focus:outline-none"
                >
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Alarm;
