import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaClock, FaSearch, FaCalendarAlt, FaTags, FaUserAlt } from 'react-icons/fa';

const BlogPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeCategory, setActiveCategory] = useState('all');
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [featuredArticle, setFeaturedArticle] = useState(null);

  const categories = [
    { id: 'all', name: 'Todos' },
    { id: 'produtividade', name: 'Produtividade' },
    { id: 'gerenciamento-tempo', name: 'Gerenciamento de Tempo' },
    { id: 'sono', name: 'Sono e Descanso' },
    { id: 'trabalho-remoto', name: 'Trabalho Remoto' },
  ];

  const blogArticles = [
    {
      id: 'pomodoro',
      title: 'Como usar o método Pomodoro para aumentar a produtividade',
      excerpt:
        'Aprenda a aplicar a técnica Pomodoro para melhorar seu foco e produtividade em apenas 25 minutos por vez.',
      date: '08/04/2025',
      author: 'Marina Silva',
      readTime: 5,
      categories: ['produtividade', 'gerenciamento-tempo'],
      image:
        'https://images.unsplash.com/photo-1513128034602-7814ccaddd4e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
      featured: false,
    },
    {
      id: 'arquitetura-habitos',
      title: 'Arquitetura de Hábitos: Como Rotinas Bem Projetadas Transformam Sua Realidade',
      excerpt:
        'Descubra como projetar hábitos intencionais que se tornam alicerces para uma vida mais produtiva e alinhada com seus objetivos.',
      date: '25/03/2025',
      author: 'Mariana Costa',
      readTime: 10,
      categories: ['produtividade', 'gerenciamento-tempo'],
      image:
        'https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
      featured: true,
    },
    {
      id: 'gerenciar-tempo',
      title: '5 maneiras de gerenciar melhor seu tempo',
      excerpt:
        'Dicas práticas e cientificamente comprovadas para otimizar sua rotina e aproveitar melhor o seu dia.',
      date: '15/03/2025',
      author: 'Carlos Mendes',
      readTime: 6,
      categories: ['gerenciamento-tempo'],
      image:
        'https://images.unsplash.com/photo-1506784983877-45594efa4cbe?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2068&q=80',
      featured: false,
    },
    {
      id: 'rotinas',
      title:
        'A importância de estabelecer rotinas: por que hábitos consistentes transformam sua vida',
      excerpt:
        'Descubra como criar e manter rotinas saudáveis para melhorar sua qualidade de vida e produtividade diária.',
      date: '28/02/2025',
      author: 'Ana Luíza Costa',
      readTime: 7,
      categories: ['produtividade', 'gerenciamento-tempo'],
      image:
        'https://images.unsplash.com/photo-1484480974693-6ca0a78fb36b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
      featured: false,
    },
    {
      id: 'time-blocking',
      title: 'Os benefícios do time blocking para organização diária',
      excerpt:
        'Descubra como dividir seu dia em blocos de tempo pode revolucionar sua produtividade e reduzir o estresse.',
      date: '05/03/2025',
      author: 'Ricardo Oliveira',
      readTime: 6,
      categories: ['gerenciamento-tempo', 'produtividade'],
      image:
        'https://images.unsplash.com/photo-1507925921958-8a62f3d1a50d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
      featured: false,
    },
    {
      id: 'sono-produtividade',
      title: 'Como monitorar seu sono para melhorar a produtividade: o guia completo',
      excerpt:
        'A relação entre qualidade do sono e desempenho durante o dia: pesquisas, dicas e ferramentas práticas.',
      date: '20/03/2025',
      author: 'Dra. Juliana Santos',
      readTime: 8,
      categories: ['sono', 'produtividade'],
      image:
        'https://images.unsplash.com/photo-1541781774459-bb2af2f05b55?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
      featured: true,
    },
    {
      id: 'trabalho-remoto',
      title: 'Gestão de tempo para trabalho remoto: estratégias que realmente funcionam',
      excerpt:
        'Estratégias eficazes baseadas em pesquisas para manter o foco e produtividade quando se trabalha de casa.',
      date: '19/02/2025',
      author: 'Fernando Gomes',
      readTime: 7,
      categories: ['trabalho-remoto', 'gerenciamento-tempo'],
      image:
        'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
      featured: false,
    },
    {
      id: 'cronotipos',
      title: 'Entenda seu cronotipo: otimize seu dia com base no seu relógio biológico',
      excerpt:
        'Descubra se você é uma pessoa matutina, vespertina ou intermediária e como estruturar seu dia para máxima produtividade.',
      date: '01/03/2025',
      author: 'Dr. Paulo Rocha',
      readTime: 9,
      categories: ['sono', 'produtividade'],
      image:
        'https://images.unsplash.com/photo-1546074177-31bfa593f731?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80',
      featured: true,
    },
    {
      id: 'mindfulness',
      title: 'Mindfulness e produtividade: como a atenção plena transforma sua eficiência',
      excerpt:
        'Técnicas de mindfulness cientificamente comprovadas para melhorar o foco, reduzir o estresse e aumentar sua produtividade.',
      date: '10/03/2025',
      author: 'Camila Torres',
      readTime: 6,
      categories: ['produtividade'],
      image:
        'https://images.unsplash.com/photo-1506126613408-eca07ce68773?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80',
      featured: false,
    },
  ];

  useEffect(() => {
    // Encontrar o artigo em destaque
    const featured = blogArticles.find(
      article => article.featured && article.id === 'sono-produtividade'
    );
    setFeaturedArticle(featured || blogArticles[4]); // Usar o artigo sobre sono como destaque ou o 5º artigo

    // Filtrar artigos com base na categoria e termo de busca
    filterArticles(activeCategory, searchTerm);

    // Configurar Meta Tags
    document.title = 'Blog | Relógio Online - Artigos sobre produtividade e gestão de tempo';

    // Meta description
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement('meta');
      metaDescription.setAttribute('name', 'description');
      document.head.appendChild(metaDescription);
    }
    metaDescription.setAttribute(
      'content',
      'Artigos aprofundados sobre produtividade, gestão de tempo, sono saudável e estratégias de trabalho para melhorar sua qualidade de vida.'
    );

    // Scrollar para o topo quando carregar a página
    window.scrollTo(0, 0);
  }, [activeCategory, searchTerm]);

  const filterArticles = (category, term) => {
    let filtered = [...blogArticles];

    // Filtrar por categoria
    if (category !== 'all') {
      filtered = filtered.filter(article => article.categories.includes(category));
    }

    // Filtrar por termo de busca
    if (term.trim() !== '') {
      filtered = filtered.filter(
        article =>
          article.title.toLowerCase().includes(term.toLowerCase()) ||
          article.excerpt.toLowerCase().includes(term.toLowerCase()) ||
          article.categories.some(cat => cat.toLowerCase().includes(term.toLowerCase()))
      );
    }

    // Excluir o artigo em destaque da lista
    if (featuredArticle) {
      filtered = filtered.filter(article => article.id !== featuredArticle.id);
    }

    setFilteredArticles(filtered);
  };

  const handleSearch = e => {
    const term = e.target.value;
    setSearchTerm(term);
    filterArticles(activeCategory, term);
  };

  const handleCategoryChange = catId => {
    setActiveCategory(catId);
    filterArticles(catId, searchTerm);
  };

  const formatDate = dateStr => {
    const parts = dateStr.split('/');
    const date = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
    return date.toLocaleDateString('pt-BR', { day: '2-digit', month: 'long', year: 'numeric' });
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Blog de Produtividade
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
            Artigos aprofundados sobre gestão de tempo, produtividade e bem-estar, respaldados por
            pesquisas científicas para ajudar você a otimizar sua vida pessoal e profissional.
          </p>
        </div>

        {/* Barra de pesquisa e filtros */}
        <div className="mb-12">
          <div className="max-w-xl mx-auto mb-8">
            <div className="flex items-center bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden border border-gray-200 dark:border-gray-700">
              <div className="pl-4">
                <FaSearch className="text-gray-400" />
              </div>
              <input
                type="text"
                placeholder="Buscar artigos..."
                value={searchTerm}
                onChange={handleSearch}
                className="w-full px-4 py-3 bg-transparent border-none focus:outline-none text-gray-700 dark:text-gray-300"
              />
            </div>
          </div>

          <div className="flex flex-wrap justify-center gap-2 mb-8">
            {categories.map(category => (
              <button
                key={category.id}
                onClick={() => handleCategoryChange(category.id)}
                className={`px-4 py-2 rounded-full transition-colors ${
                  activeCategory === category.id
                    ? 'bg-primary-600 text-white'
                    : 'bg-gray-200 text-gray-700 dark:bg-gray-700 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-600'
                }`}
              >
                {category.name}
              </button>
            ))}
          </div>
        </div>

        {/* Artigo em destaque */}
        {featuredArticle && (
          <div className="mb-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6 flex items-center">
              <span className="inline-block bg-primary-600 text-white px-3 py-1 rounded-lg mr-3 text-sm">
                Em destaque
              </span>
              Artigo em Destaque
            </h2>
            <Link
              to={`/blog/${featuredArticle.id}`}
              className="block bg-white dark:bg-gray-800 rounded-xl shadow-xl overflow-hidden transition-all duration-300 hover:shadow-2xl hover:scale-[1.01]"
            >
              <div className="md:flex">
                <div className="md:w-2/5 relative overflow-hidden h-64 md:h-auto">
                  <img
                    src={featuredArticle.image}
                    alt={featuredArticle.title}
                    className="w-full h-full object-cover transition-transform duration-700 hover:scale-110"
                  />
                </div>
                <div className="md:w-3/5 p-6 md:p-8">
                  <div className="flex items-center text-sm text-gray-500 dark:text-gray-400 mb-3">
                    <FaCalendarAlt className="mr-2" />
                    <span>{formatDate(featuredArticle.date)}</span>
                    <span className="mx-2">•</span>
                    <FaClock className="mr-2" />
                    <span>{featuredArticle.readTime} min de leitura</span>
                  </div>
                  <h3 className="text-2xl md:text-3xl font-bold text-gray-900 dark:text-white mb-4">
                    {featuredArticle.title}
                  </h3>
                  <p className="text-gray-600 dark:text-gray-300 mb-6 line-clamp-3">
                    {featuredArticle.excerpt}
                  </p>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="w-10 h-10 rounded-full bg-primary-100 dark:bg-primary-900 flex items-center justify-center text-primary-700 dark:text-primary-300 mr-3">
                        <FaUserAlt />
                      </div>
                      <span className="text-gray-700 dark:text-gray-300">
                        {featuredArticle.author}
                      </span>
                    </div>
                    <span className="text-primary-600 dark:text-primary-400 font-medium">
                      Ler artigo completo →
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        )}

        {/* Lista de artigos */}
        <div>
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
            {searchTerm.trim() !== ''
              ? 'Resultados da busca'
              : activeCategory !== 'all'
              ? `Artigos sobre ${categories.find(c => c.id === activeCategory).name}`
              : 'Artigos Recentes'}
          </h2>

          {filteredArticles.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {filteredArticles.map(article => (
                <Link
                  key={article.id}
                  to={`/blog/${article.id}`}
                  className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl hover:translate-y-[-5px]"
                >
                  <div className="relative h-48 overflow-hidden">
                    <img
                      src={article.image}
                      alt={article.title}
                      className="w-full h-full object-cover transition-transform duration-700 hover:scale-110"
                    />
                    <div className="absolute bottom-0 left-0 bg-gradient-to-t from-black/70 to-transparent w-full h-20"></div>
                    <div className="absolute bottom-3 left-4 flex flex-wrap gap-2">
                      {article.categories.map(cat => (
                        <span
                          key={cat}
                          className="bg-primary-600/80 text-white text-xs px-2 py-1 rounded"
                        >
                          {categories.find(c => c.id === cat)?.name || cat}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="p-6">
                    <div className="flex items-center text-sm text-gray-500 dark:text-gray-400 mb-3">
                      <FaCalendarAlt className="mr-2" />
                      <span>{formatDate(article.date)}</span>
                      <span className="mx-2">•</span>
                      <FaClock className="mr-2" />
                      <span>{article.readTime} min de leitura</span>
                    </div>
                    <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-3 line-clamp-2">
                      {article.title}
                    </h3>
                    <p className="text-gray-600 dark:text-gray-300 mb-4 line-clamp-3">
                      {article.excerpt}
                    </p>
                    <div className="flex items-center justify-between mt-4 pt-4 border-t border-gray-200 dark:border-gray-700">
                      <div className="flex items-center">
                        <div className="w-8 h-8 rounded-full bg-primary-100 dark:bg-primary-900 flex items-center justify-center text-primary-700 dark:text-primary-300 mr-2 text-xs">
                          <FaUserAlt />
                        </div>
                        <span className="text-gray-700 dark:text-gray-300 text-sm">
                          {article.author}
                        </span>
                      </div>
                      <span className="text-primary-600 dark:text-primary-400 font-medium text-sm">
                        Ler mais →
                      </span>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            <div className="text-center py-12 bg-white dark:bg-gray-800 rounded-lg shadow">
              <p className="text-xl text-gray-600 dark:text-gray-300">
                Nenhum artigo encontrado para sua busca por &quot;{searchTerm}&quot;.
              </p>
              <button
                onClick={() => {
                  setSearchTerm('');
                  setActiveCategory('all');
                  filterArticles('all', '');
                }}
                className="mt-4 px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors"
              >
                Limpar filtros
              </button>
            </div>
          )}
        </div>

        {/* Assine a newsletter */}
        <div className="mt-16 bg-gradient-to-r from-primary-600 to-primary-800 text-white rounded-xl p-8 shadow-lg">
          <div className="md:flex items-center justify-between">
            <div className="md:w-3/5 mb-6 md:mb-0">
              <h3 className="text-2xl font-bold mb-3">Fique por dentro das novidades</h3>
              <p>
                Receba artigos, dicas e ferramentas exclusivas para aumentar sua produtividade
                diretamente no seu e-mail.
              </p>
            </div>
            <div className="md:w-2/5">
              <div className="flex">
                <input
                  type="email"
                  placeholder="Seu e-mail"
                  className="flex-grow px-4 py-3 rounded-l-md text-gray-900 focus:outline-none"
                />
                <button className="bg-gray-900 hover:bg-gray-800 transition-colors px-4 py-3 rounded-r-md">
                  Assinar
                </button>
              </div>
              <p className="text-sm mt-2 text-white/80">
                Prometemos não enviar spam. Você pode cancelar a qualquer momento.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
