import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Timer from '../components/Timer';
import PredefinedTimers from '../components/PredefinedTimers';

// Som de alarme em base64 (beep simples)
const alarmSound =
  'data:audio/wav;base64,UklGRigAAABXQVZFZm10IBAAAAABAAEARKwAAIhYAQACABAAZGF0YQQAAAB/f39/f39/fw==';

// Definição dos timers pré-configurados
const predefinedTimers = {
  cooking: {
    'boil-eggs': { name: 'Ovos Cozidos', time: { hours: 0, minutes: 10, seconds: 0 } },
    pasta: { name: 'Massa', time: { hours: 0, minutes: 12, seconds: 0 } },
    rice: { name: 'Arroz', time: { hours: 0, minutes: 20, seconds: 0 } },
    chicken: { name: 'Frango', time: { hours: 0, minutes: 30, seconds: 0 } },
    beef: { name: 'Carne', time: { hours: 0, minutes: 45, seconds: 0 } },
    fish: { name: 'Peixe', time: { hours: 0, minutes: 15, seconds: 0 } },
    vegetables: { name: 'Legumes', time: { hours: 0, minutes: 8, seconds: 0 } },
    soup: { name: 'Sopa', time: { hours: 0, minutes: 25, seconds: 0 } },
    cake: { name: 'Bolo', time: { hours: 0, minutes: 40, seconds: 0 } },
    bread: { name: 'Pão', time: { hours: 0, minutes: 35, seconds: 0 } },
  },
  exercise: {
    'warm-up': { name: 'Aquecimento', time: { hours: 0, minutes: 5, seconds: 0 } },
    stretching: { name: 'Alongamento', time: { hours: 0, minutes: 10, seconds: 0 } },
    plank: { name: 'Prancha', time: { hours: 0, minutes: 1, seconds: 0 } },
    'push-ups': { name: 'Flexões', time: { hours: 0, minutes: 2, seconds: 0 } },
    squats: { name: 'Agachamentos', time: { hours: 0, minutes: 3, seconds: 0 } },
    'jumping-jacks': { name: 'Polichinelos', time: { hours: 0, minutes: 1, seconds: 30 } },
    burpees: { name: 'Burpees', time: { hours: 0, minutes: 1, seconds: 0 } },
    'mountain-climbers': { name: 'Escaladores', time: { hours: 0, minutes: 1, seconds: 30 } },
    lunges: { name: 'Avanços', time: { hours: 0, minutes: 2, seconds: 0 } },
    'sit-ups': { name: 'Abdominais', time: { hours: 0, minutes: 2, seconds: 0 } },
  },
  study: {
    pomodoro: { name: 'Pomodoro', time: { hours: 0, minutes: 25, seconds: 0 } },
    'short-break': { name: 'Pausa Curta', time: { hours: 0, minutes: 5, seconds: 0 } },
    'long-break': { name: 'Pausa Longa', time: { hours: 0, minutes: 15, seconds: 0 } },
    reading: { name: 'Leitura', time: { hours: 0, minutes: 30, seconds: 0 } },
    writing: { name: 'Escrita', time: { hours: 0, minutes: 45, seconds: 0 } },
    review: { name: 'Revisão', time: { hours: 0, minutes: 20, seconds: 0 } },
    practice: { name: 'Prática', time: { hours: 0, minutes: 40, seconds: 0 } },
    memorization: { name: 'Memorização', time: { hours: 0, minutes: 15, seconds: 0 } },
    'problem-solving': {
      name: 'Resolução de Problemas',
      time: { hours: 0, minutes: 50, seconds: 0 },
    },
    'group-study': { name: 'Estudo em Grupo', time: { hours: 1, minutes: 0, seconds: 0 } },
  },
};

function TimerPage() {
  const { timerId } = useParams();
  const [timerName, setTimerName] = useState('');
  const [timerTime, setTimerTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [timerRunning, setTimerRunning] = useState(false);
  const [timerPaused, setTimerPaused] = useState(false);
  const [sound] = useState(new Audio(alarmSound));

  useEffect(() => {
    if (timerId) {
      // Search for the timer in all categories
      for (const category of Object.keys(predefinedTimers)) {
        if (predefinedTimers[category][timerId]) {
          setTimerName(predefinedTimers[category][timerId].name);
          setTimerTime(predefinedTimers[category][timerId].time);
          break;
        }
      }
    }
  }, [timerId]);

  useEffect(() => {
    let interval;
    if (timerRunning && !timerPaused) {
      interval = setInterval(() => {
        setTimerTime(prevTime => {
          const totalSeconds = prevTime.hours * 3600 + prevTime.minutes * 60 + prevTime.seconds - 1;

          if (totalSeconds < 0) {
            clearInterval(interval);
            sound.play();
            setTimerRunning(false);
            return { hours: 0, minutes: 0, seconds: 0 };
          }

          const hours = Math.floor(totalSeconds / 3600);
          const minutes = Math.floor((totalSeconds % 3600) / 60);
          const seconds = totalSeconds % 60;

          return { hours, minutes, seconds };
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timerRunning, timerPaused, sound]);

  const handleStart = () => {
    setTimerRunning(true);
    setTimerPaused(false);
  };

  const handlePause = () => {
    setTimerPaused(true);
  };

  const handleStop = () => {
    setTimerRunning(false);
    setTimerPaused(false);
    if (timerId) {
      // Procurar o timer em todas as categorias
      for (const category of Object.keys(predefinedTimers)) {
        if (predefinedTimers[category][timerId]) {
          setTimerTime(predefinedTimers[category][timerId].time);
          break;
        }
      }
    } else {
      setTimerTime({ hours: 0, minutes: 0, seconds: 0 });
    }
  };

  const handleTimeChange = (type, value) => {
    if (!timerRunning) {
      setTimerTime(prev => ({
        ...prev,
        [type]: Math.max(0, Math.min(type === 'hours' ? 23 : 59, parseInt(value) || 0)),
      }));
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Hero Section */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            {timerName || 'Timer Personalizado'}
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300">
            Configure seu timer e gerencie seu tempo com precisão
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Timer Section */}
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">Timer</h2>
            <Timer
              initialTime={timerTime}
              isRunning={timerRunning}
              isPaused={timerPaused}
              onStart={handleStart}
              onPause={handlePause}
              onStop={handleStop}
              onTimeChange={handleTimeChange}
            />
          </div>

          {/* Predefined Timers Section */}
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
              Timers Pré-configurados
            </h2>
            <PredefinedTimers />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TimerPage;
